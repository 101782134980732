import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SpText from "../../../components/atoms/SpText";
import { getGroupsById } from "../../../models/actions/Groups";
import { labels } from "../../shared/translations";
import GroupEditSidebar from "../shared/GroupEditSidebar";
import { rollbar } from "../../../utils/common";
import { getPromsResults } from "../../patients/patientsStatisticsAnalytics/tempChart/chartHelper.js";
import moment from "moment";
import PromsChart from "./promsChart.jsx";
import Cards from "./promsCards.jsx";
import PaginatedTable from "../../patients/patientsStatisticsAnalytics/tempChart/paginatedTable.js";
import Agg from "../../patients/patientsStatisticsAnalytics/tempChart/workload_analysis.jsx";
import { getFeedbackForChart } from "../../../models/actions/Activity.js";
import {
  getAssessmentMonitList,
  getGroupChart,
  getTestsResultChart,
} from "../../../models/actions/Assessment.js";
import {
  SpSelect,
  SpSelectMenuItem,
} from "../../../components/atoms/SpSelect.js";
import {
  ResponsiveContainer,
  ScatterChart, Scatter, 
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Dot
} from "recharts";
import SpLoader from "../../../components/atoms/SpLoader.js";
import { VerticalAlignTop } from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const ContRoom = ({ ...props }) => {
  const [currentGroup, setCurrentGroup] = useState();
  const [uniqueTestNames, setUniqueTestNames] = useState([]);
  const [mergedTestData, setMergedTestData] = useState([]);
  const { groupId } = useParams();
  const [groupMean, setGroupMean] = useState([]);
  const [groupMStd, setGroupStd] = useState([]);
  const [listProms, setlistProms] = useState([]);
  const [groupMeanWork, setGroupMeanWork] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [selectedTest, setSelectedTest] = useState("");
  const [selectedProms, setSelectedProms] = useState([]);


  const fetchData = async () => {
    try {
      setLoading(true);
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);
      const res = await getGroupChart({ id_group: groupId });
      setMergedTestData(res?.testDataAllMerged);
      setUniqueTestNames(res?.testNames);
      setGroupMean(res?.promsMean);
      let lstProms = []
      if (res?.promsMean) {
        const firstKey = Object.keys(res.promsMean)[0];
        for (let key in res?.promsMean[firstKey]) {
          if (key !== "Date") {
            lstProms.push(key)
          }
        }
      }
      setlistProms(lstProms)

      setGroupStd(res?.promsStd);
      setGroupMeanWork(res?.workMean);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      //props.snackbarShowErrorMessage(error);
      rollbar.error("Groups - groupData", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (listProms.length > 0) {
      setSelectedProms([listProms[0]]);
    }
  }, [listProms]);

  // Function to add random displacement to y-values
  const addRandomDisplacement = (data, maxDisplacement) => {
    const newData = [];
    const groupedData = {};

    // Group data by x-values
    data.forEach(point => {
      const xValue = point.value; // Adjust precision as needed
      if (!groupedData[xValue]) {
        groupedData[xValue] = [];
      }
      groupedData[xValue].push(point);
    });

    // Add random displacement to y-values within each group
    Object.keys(groupedData).forEach(xValue => {
      const points = groupedData[xValue];
      if (points.length > 1) {
        // Only add displacement to one point in the group
        const displacement = (Math.random() * 2 * maxDisplacement) - maxDisplacement;
        const pointToDisplace = points[0]; // Choose the first point to displace
        const newY = pointToDisplace.index + displacement;
        newData.push({ ...pointToDisplace, index: newY });

        // Add the remaining points without displacement
        points.slice(1).forEach(point => {
          newData.push({ ...point });
        });
      } else {
        // Add the point without displacement if it is the only one in the group
        newData.push({ ...points[0] });
      }
    });

    return newData;
  };


  const SwarmPlot = ({ data }) => {
    let testNameY = data[0].testName;
    const meanValue =
      data.reduce((acc, current) => acc + current.value, 0) / data.length;
    // Calculate the standard deviation
    const deviations = data.map((item) => Math.pow(item.value - meanValue, 2));
    const stdDeviation = Math.sqrt(
      deviations.reduce((acc, val) => acc + val, 0) / data.length
    );

    for (let p of data) {
      p['index'] = 0
    }

    let modifiedData = data;
    if (data.length > 1) {
      modifiedData = addRandomDisplacement(data, 0.2);
    } else {
      modifiedData = data;
    }

    const RenderDot = ({ cx, cy }) => {
      return <Dot cx={cx} cy={cy} strokeWidth={3} stroke="#8884d8" fill='#8884d870' r={8} />;
    };

    return (
      
      <Grid item xs={4} container direction="row" spacing={1}>
        <ResponsiveContainer width="100%" height={150}>
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 20
            }}
          >
            <text x="50%" y="10" textAnchor="middle" dominantBaseline="middle" fontSize="1em" fill="#ffffff">{testNameY}</text>
            <CartesianGrid stroke="#cccccc30" horizontal={false} />
            <YAxis type="number" dataKey="index" domain={[-0.5,0.5]} tick={false} axisLine={false}/>
            <XAxis type="number" dataKey="value" />
            <Tooltip 
              formatter={(value, name, props) => {
                const index = props.payload.index;
                if (value !== index) {
                  const player = props.payload.player; // Access player from data
                  const val = props.payload.value; // Access index from data
                  return [`${player} = ${val}`, '']; // Format tooltip content
                } else {
                  return ['']
                }
              }}
            />
            <Scatter data={modifiedData} shape={RenderDot}/>
            <ReferenceLine
              x={meanValue}
              stroke="#FFFFFF80"
              strokeDasharray="3 3"
              strokeWidth={1.8}
            />
            <ReferenceLine
              x={meanValue + stdDeviation}
              stroke="#FFFFFF70"
              strokeDasharray="2 2"
              strokeWidth={1.3}
            />
            <ReferenceLine
              x={meanValue - stdDeviation}
              stroke="#FFFFFF70"
              strokeDasharray="2 2"
              strokeWidth={1.3}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Grid>
    );
  };

  const TestChart = ({ dataTests }) => {
    let plots = [];
    let subtestNames = Object.keys(dataTests);
    for (let subtest = 0; subtest < subtestNames.length; subtest++) {
      let subtestData = dataTests[subtestNames[subtest]];
      const transformedData = [];
      for (let el = 0; el < subtestData.length; el++) {
        let rawData = subtestData[el];

        // Iterate through the data keys (sprint types)
        const name = rawData.Name;
        const value = rawData[Object.keys(rawData)[0]];
        const date = Object.keys(rawData)[0];
        const category = 0;
        const testName = subtestNames[subtest];

        // Push the transformed data point to the array
        transformedData.push({
          category: category,
          player: name,
          value: value,
          testName: testName,
          date: date,
        });
      }
      plots.push(<SwarmPlot key={subtest} data={transformedData} />);
    }
    return <>{plots}</>;
  };

  const ResultsTable = ( {data} ) => {

    const tableStyle = {
      width: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
    };
  
    const thStyle = {
      backgroundColor: '#3d988e', // green background for header
      color: '#ffffff', // white text
      padding: '8px',
      textAlign: 'left',
    };
  
    const tdStyle = {
      color: '#ffffff', // white text
      padding: '8px',
      borderBottom: '1px solid #ddd',
    };

    return (
      <div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Test</th>
              <th style={{ ...thStyle, width: '15%' }}>Name</th>
              <th style={{ ...thStyle, width: '15%' }}>Date</th>
              <th style={{ ...thStyle, width: '15%' }}>Score</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data).map(([testType, results]) =>
              results.map((result, index) => (
                <tr key={`${testType}-${index}`}>
                  <td style={tdStyle}>{testType}</td>
                  <td style={{ ...tdStyle, width: '15%' }}>{result[Object.keys(result)[1]]}</td>
                  <td style={{ ...tdStyle, width: '15%' }}>{Object.keys(result)[0]}</td>
                  <td style={{ ...tdStyle, width: '15%' }}>{result[Object.keys(result)[0]] !== null && result[Object.keys(result)[0]] !== undefined
                                        ? parseFloat(result[Object.keys(result)[0]]).toFixed(1)
                                        : '-'}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const [expanded, setExpanded] = useState(false);

  const handleSelectChange = (e) => {
    setSelectedProms(e.target.value);
  };

  const handleAccordionChange = (isExpanded) => {
    setExpanded(isExpanded);
  }

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={12} container spacing={2} direction="row">
        <Grid item xs={2}>
          <GroupEditSidebar
            props={props}
            groupId={groupId}
            history={history}
            currentGroup={currentGroup}
          />
        </Grid>

        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={8} container direction="row" spacing={1}>
            <h1 style={{ color: "#3d988e", textAlign: "center", fontSize: 50 }}>
              PROMS
            </h1>
          </Grid>
          <Cards data={groupMean} />
          <PromsChart dataM={groupMean} dataS={groupMStd} selP={selectedProms} features={selectedProms} general={true} />
          <Grid item xs={12} container direction="row" spacing={1}>
            <Accordion
              expanded={expanded}
              onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
              style={{
                background: "transparent",
                width: "95vw",
                display: "block",
                padding: "2em",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#31caad" }} />}
                style={{ background: "transparent", border: "1px solid #31caad" }}
              >
                <SpText variant="text">{labels.patient.chart.trendDetails}</SpText>
              </AccordionSummary>
              <AccordionDetails>
                <SpSelect
                  displayLabel={true}
                  label={labels.patient.chart.selectProm}
                  onChange={handleSelectChange}
                  value={selectedProms}
                  multiple={true}
                >
                  <SpSelectMenuItem value=""></SpSelectMenuItem>
                  {listProms.map((testName, index) => (
                    <SpSelectMenuItem key={index} value={testName}>
                      {testName}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              </AccordionDetails>
              <Grid item xs={12} container direction="row" spacing={1}>
                {selectedProms.map((prom, index) => (
                  <PromsChart 
                    dataM={groupMean} 
                    dataS={groupMStd} 
                    selP={prom} 
                    features={listProms} 
                  />
                ))}
              </Grid>
            </Accordion>
          </Grid>
          {groupMean.length > 0 && (
            <PaginatedTable data={[...groupMean].reverse()} />
          )}
        </Grid>
        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={8} container direction="row" spacing={1}>
            <h1 style={{ color: "#3d988e", textAlign: "center", fontSize: 50 }}>
              WORKLOAD
            </h1>
          </Grid>
          {groupMeanWork.length > 0 && (
            <Grid item xs={12} container direction="row" spacing={1}>
              <Agg data={groupMeanWork} />
            </Grid>
          )}
          {groupMeanWork.length > 0 && (
            <PaginatedTable data={[...groupMeanWork].reverse()} />
          )}
        </Grid>
        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={8} container direction="row" spacing={1}>
            <h1 style={{ color: "#3d988e", textAlign: "center", fontSize: 50 }}>
              TEST
            </h1>
          </Grid>
        </Grid>
        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={12} container direction="row" spacing={1}>
            <SpSelect
              displayLabel={true}
              label={labels.patient.chart.selectTest}
              onChange={(e) => setSelectedTest(e.target.value)}
              value={selectedTest}
            >
              <SpSelectMenuItem value=""></SpSelectMenuItem>

              {uniqueTestNames.map((testName, index) => (
                <SpSelectMenuItem key={index} value={testName}>
                  {testName}
                </SpSelectMenuItem>
              ))}
            </SpSelect>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            spacing={1}
            style={{ paddingTop: "2em" }}
          >
            {selectedTest && (
              <>
                <TestChart dataTests={mergedTestData[selectedTest]} />
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            spacing={1}
            style={{ paddingTop: "2em" }}
          >
            {selectedTest && (
                <ResultsTable data={mergedTestData[selectedTest]} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
