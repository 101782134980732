import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpCheckbox from "../../components/atoms/SpCheckbox";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { Divider, Grid, TableCell } from "@material-ui/core";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import {
  findPatientByPk,
  saveControlQuestionnaires,
} from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { getAllPromsSurveys, getFinalScore } from "../../models/actions/Proms";
import SpDialog from "../../components/atoms/SpDialog";
import { isFeatureFlagEnabled } from "../../utils/common";
import { FilterAutocomplete } from "./patientsStatisticsAnalytics/patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFiltersHelper";
import { theme } from "../../components/theme";
import PatientsMonitoringDetailProms from "./patientsMonitoring/PatientsMonitoringDetailProms";
import moment from "moment";
import PatientsControlQuestionHelper from "./PatientControlQuestionHelper";

const PatientsControlQuestion = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [promsQuestList, setPromsQuestList] = useState([]);
  const [promSelected, setPromSelected] = useState(null);
  const [dialogDetailOpen, setDialogDetailOpen] = useState(false);
  const [selectedRecordDetail, setSelectedRecordDetail] = useState(null);

  const [sendNotification, setSendNotification] = useState(false);
  const [isProfessionalNotifEnabled, setIsProfessionalNotifEnabled] =
    useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedProms, setSelectedProms] = useState();
  const [detailPresentationProms, setDetailPresentationProms] = useState(false);

  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { control, register, handleSubmit, getValues, reset, setValue } =
    useForm({
      defaultValues: {
        proms: null,
        frequency: null,
        duration: null,
      },
      shouldUnregister: false,
    });

  const saveControlsQuestionnaires = async () => {
    try {
      if (selectedDays.length === 0) {
        props.snackbarShowErrorMessage(
          labels.patient.controlQuestions.insertAllData
        );
        return;
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // get email's patient
      const saveResponse = await saveControlQuestionnaires({
        id_patients: [patId],
        id_questionnaires: promSelected,
        frequency: getValues().frequency,
        days: selectedDays.map(({ id }) => id),
        monitoring_questions: promSelected.is_monit_question,
        emails: sendNotification ? [currentPatient.email] : null,
        tz: timeZone,
        start_time: moment().format("HH:mm"),
      });
      if (saveResponse?.message) {
        props.snackbarShowMessage(saveResponse.message);
        reset({
          proms: null,
          frequency: null,
        });
        setPromSelected(null);
        setSelectedDays([]);
      } else {
        props.snackbarShowErrorMessage(saveResponse.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    // controllo se la feature flag é abilitata
    let isEnabled = await isFeatureFlagEnabled("professional_notification");
    setIsProfessionalNotifEnabled(isEnabled);
    try {
      setLoading(true);
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
      const resultProms = await getAllPromsSurveys();
      setPromsQuestList([...resultProms]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => await fetchData(), []);

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "10px" }}>
      <SpText variant={"text"}>{props.label}</SpText>
      <SpText variant={"h4ComponentLabelRegular"}>{props.value}</SpText>
    </Grid>
  );

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item container xs={12} spacing={2} direction="row">
          <Grid item xs={12}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          {!detailPresentationProms && (
            <Grid item xs={12} spacing={1} container direction="column">
              <form onSubmit={handleSubmit(saveControlsQuestionnaires)}>
                <Grid item xs={"auto"}>
                  <SpText variant="h1PageTitle">
                    {labels.patient.controlQuestions.title.toUpperCase()}
                  </SpText>
                  <SpText variant="h1Subtitle">
                    {labels.patient.controlQuestions.subtitle +
                      " " +
                      currentPatient?.givenName +
                      " " +
                      currentPatient?.familyName}
                  </SpText>
                </Grid>
                <Grid item xs={"auto"} container spacing={1} direction="column">
                  <Grid item xs>
                    <Controller
                      style={{ marginTop: "3%" }}
                      render={(props) => (
                        <SpSelect
                          label={
                            labels.patient.controlQuestions.selectProms.title
                          }
                          value={props.value}
                          formControlWidth={300}
                          selectPlaceholder={
                            labels.patient.controlQuestions.selectProms
                              .placeholder
                          }
                          onChange={(e) => {
                            props.onChange(e.target.value);
                            setPromSelected(e.target.value);
                          }}
                        >
                          {promsQuestList.map((p) => (
                            <SpSelectMenuItem key={p.id} value={p.id}>
                              {p.name
                                ? psTranslate(p.name)
                                : labels.patient.controlQuestions.selectProms
                                    .placeholder}
                            </SpSelectMenuItem>
                          ))}
                        </SpSelect>
                      )}
                      defaultValue={""}
                      name={"proms"}
                      control={control}
                    />
                    {isProfessionalNotifEnabled && (
                      <SpCheckbox
                        label={
                          labels.patient.controlQuestions.selectProms
                            .sendNotification
                        }
                        rightlabel={true}
                        formControlStyle={{
                          justifyContent: "flex-start",
                          marginTop: "8px",
                        }}
                        checked={sendNotification}
                        onChange={(evnt) => {
                          setSendNotification(evnt.target.checked);
                        }}
                      />
                    )}
                  </Grid>

                  {promSelected && (
                    <Grid item container xs={8} spacing={1}>
                      <Grid item xs={6}>
                        <FilterAutocomplete
                          placeholder={
                            labels.patient.controlQuestions.selectFrequency
                              .title
                          }
                          value={selectedDays}
                          onChange={(_, newValue) => {
                            setSelectedDays(newValue);
                          }}
                          renderOptions={selectedDays}
                          options={labels.week}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ paddingTop: "0.5em" }}>
                        <SpTextInput
                          style={{ minWidth: 500 }}
                          name="frequency"
                          label={labels.patient.controlQuestions.selectFrequency.placeholder.toUpperCase()}
                          placeholder={
                            labels.patient.controlQuestions.selectFrequency
                              .placeholder
                          }
                          inputRef={register}
                          type="number"
                          minValue={1}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {promSelected && (
                    <Grid item xs>
                      <SpButton
                        style={{ minWidth: 300 }}
                        buttonType="accept"
                        type="submit"
                        variant="column"
                        text={labels.patient.controlQuestions.sendQuestionButton.toUpperCase()}
                      />
                    </Grid>
                  )}
                </Grid>
                <PatientsControlQuestionHelper
                  setLoading={setLoading}
                  patId={patId}
                />
              </form>
            </Grid>
          )}
        </Grid>
      </Grid>

      <SpDialog
        open={dialogDetailOpen}
        setOpen={setDialogDetailOpen}
        title={labels.patient.controlQuestions.detailDialog.title}
        style={{ marginRight: "60%" }}
      >
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <>
            <Grid item container xs={12} spacing={2}>
              <Grid xs={12}>
                <SpText variant="text">{`${labels.patient.controlQuestions.detailDialog.questTitle}`}</SpText>
              </Grid>
              <Grid xs={12}>
                <SpText variant="h4ComponentLabelRegular">
                  {psTranslate(selectedRecordDetail?.title)}
                </SpText>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Divider
                style={{
                  padding: "1px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  backgroundColor: theme.colors.primary.lightBlue,
                  width: "100%",
                }}
              />

              <Grid xs={12} direction="column" style={{ marginTop: "1%" }}>
                {selectedRecordDetail?.data?.questions?.map((question) => (
                  <LabelValue
                    label={psTranslate(question.question)}
                    value={question?.answers
                      ?.map((a) => psTranslate(a.answer))
                      ?.join(", ")}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        </Grid>
      </SpDialog>
      {detailPresentationProms && (
        <Grid item xs={12} spacing={1} container direction="column">
          <SpButton
            buttonType="accept"
            variant="none"
            style={{ width: "100%" }}
            text={labels.patient.presentation.detail.stepper.step6.close.toUpperCase()}
            onClick={() => setDetailPresentationProms(false)}
          />
        </Grid>
      )}
      {detailPresentationProms && (
        <PatientsMonitoringDetailProms
          closeAssessmentAdd={closeAssessmentAdd}
          proms={selectedProms}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default withSnackbar(PatientsControlQuestion);
