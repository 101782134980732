/* eslint-disable import/order */
import React, { useState, useEffect } from "react";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import { useLocation } from "react-router-dom";
import SpDialog from "../../../components/atoms/SpDialog";
import { Grid } from "@material-ui/core";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import { deletePatient } from "../../../models/actions/Patients";
import { ArrowBack } from "@material-ui/icons";
import { getUserPermission } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { isFeatureFlagEnabled } from "../../../utils/common";

import { titleCase } from "../../../utils/common";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import LogoUrl from "../../../assets/icon/iconSymbol.png";

const drawerWidth = "15em";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#20262E",
    width: drawerWidth,
  },
}));

const SidebarNavColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

import {
  getPatientSidebarRoute,
  routesPatients,
} from "./PatientsEditSidebarRoutes";
import SpIconButton, {
  SpIconButtonMenu,
} from "../../../components/atoms/SpIconButton";
import { LogoContainer, LogoMenu } from "../../shared/logo/style";
import { theme } from "../../../components/theme";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const PatientsEditSidebar = ({ patId, history, props, currentPatient }) => {
  const [openDeletePatient, setOpenDeletePatient] = useState();
  const [statisticsPermission, setStatisticsPermission] = useState(false);
  const [injuryEnabled, setInjuryEnabled] = useState(false);
  const [illnessEnabled, setIllnessEnabled] = useState(false);
  const [analyticsPermission, setAnalyticsPermission] = useState(false);
  const [medicalNotesPermission, setMedicalNotesPermission] = useState(false);
  const [isMedicalNotesEnabled, setIsMedicalNotesEnabled] = useState(false);
  const [isStatisticsAnalyticsEnabled, setIsStatisticsAnalyticsEnabled] =
    useState(false);
  const [isChartEnabled, setIsChartEnabled] = useState(false);
  const [isStatisticsEnabled, setIsStatisticsEnabled] = useState(false);
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(false);
  const [isPhysioResourcesEnabled, setIsPhysioResourcesEnabled] =
    useState(false);
  const [isMedicalResourcesEnabled, setIsMedicalResourcesEnabled] =
    useState(false);
  const [isAlertEnabled, setIsAlertEnabled] = useState(false);

  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleMenuClick = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    const isTempMedicalNotesEnabled = await isFeatureFlagEnabled(
      "medical-notes"
    );

    setIsMedicalNotesEnabled(isTempMedicalNotesEnabled);

    const tempStatisticPermission = await getUserPermission(
      "manage:patient-statistics",
      getAccessTokenSilently
    );
    const tempAnalyticPermission = await getUserPermission(
      "manage:patient-analytics",
      getAccessTokenSilently
    );
    const tempMedicalNotesPermission = await getUserPermission(
      "manage:medical-notes",
      getAccessTokenSilently
    );
    const tempStatisticsAnalyticsPermission = await isFeatureFlagEnabled(
      "statistics-analytics"
    );
    const tempInjuryPermission = await getUserPermission(
      "new-injury",
      getAccessTokenSilently
    );
    const tempIllnessPermission = await getUserPermission(
      "new-illness",
      getAccessTokenSilently
    );
    const tempChartPermission = await getUserPermission(
      "manage:chart",
      getAccessTokenSilently
    );

    const tempAlertPermission = await getUserPermission(
      "manage:alert",
      getAccessTokenSilently
    );

    setIsAlertEnabled(tempAlertPermission);

    const tempStatisticsPermission = await isFeatureFlagEnabled("statistics");
    const tempAnalyticsPermission = await isFeatureFlagEnabled("analytics");
    setInjuryEnabled(tempInjuryPermission);
    setIllnessEnabled(tempIllnessPermission);
    setIsChartEnabled(tempChartPermission);
    setIsStatisticsAnalyticsEnabled(tempStatisticsAnalyticsPermission);
    setIsStatisticsEnabled(tempStatisticsPermission);
    setIsAnalyticsEnabled(tempAnalyticsPermission);

    const tempPhysioResourcesPermission = await getUserPermission(
      "manage:physio-resources",
      getAccessTokenSilently
    );
    const tempMedicalResourcesPermission = await getUserPermission(
      "manage:medical-resources",
      getAccessTokenSilently
    );
    setIsPhysioResourcesEnabled(tempPhysioResourcesPermission);
    setIsMedicalResourcesEnabled(tempMedicalResourcesPermission);
    setStatisticsPermission(tempStatisticPermission);
    setAnalyticsPermission(tempAnalyticPermission);
    setMedicalNotesPermission(tempMedicalNotesPermission);
  }, []);

  const getTextVariant = (permission, path) => {
    if (!permission) return "disable";
    if (location.pathname !== path) return `sidebarItem`;
  };

  const removePatient = async () => {
    try {
      const response = await deletePatient({ id_patient: parseInt(patId) });
      setOpenDeletePatient(false);
      if (response?.error) {
        props.snackbarShowErrorMessage(response?.error);
      } else {
        props.snackbarShowMessage(response?.message);
        history.push(`/patients`);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <Grid item xs={12} style={{ paddinLeft: "8px" }}>
        <TitleToolbarSection>
          <ArrowBack
            onClick={() => history.push("/patients")}
            style={{
              width: 15,
              color: theme.colors.primary.lightBlue,
              height: 15,
              cursor: "pointer",
            }}
          />
          <SpText
            onClick={() => history.push("/patients")}
            variant="h4ComponentLabelColored"
            style={{ marginRight: "5px" }}
          >{`${labels.patient.component.backButtonLista} / `}</SpText>
          <SpText
            onClick={() => history.push("/patients")}
            variant="h4ComponentLabel"
          >{`${titleCase(
            currentPatient?.givenName ? currentPatient?.givenName : ""
          )} ${titleCase(
            currentPatient?.familyName ? currentPatient?.familyName : ""
          )}`}</SpText>
          <SpIconButtonMenu onMouseEnter={() => setIsDrawerOpen(true)}>
            <MenuIcon style={{ color: "#31caad" }} id="patientsMenu" />
          </SpIconButtonMenu>
        </TitleToolbarSection>
      </Grid>

      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        SlideProps={{
          timeout: { enter: 300, exit: 200 },
        }}
      >
        <SidebarNavColumn>
          <Grid
            container
            xs={12}
            style={{ alignContent: "center" }}
            direction="row"
          >
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "4em", paddingTop: "1em" }}
            >
              <LogoMenu id={"logoImage"} src={LogoUrl} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navStatus"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.status, patId)
              )}
              onClick={() =>
                history.push(
                  getPatientSidebarRoute(routesPatients.status, patId)
                )
              }
              text={labels.patient.sideBarPatEdit.dettPaz.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navPersonalDetails"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.registry, patId)
              )}
              onClick={() =>
                history.push(
                  getPatientSidebarRoute(routesPatients.registry, patId)
                )
              }
              text={labels.patient.sideBarPatEdit.anagPat.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navHistory"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.pastHistory, patId)
              )}
              onClick={() =>
                history.push(
                  getPatientSidebarRoute(routesPatients.pastHistory, patId)
                )
              }
              text={labels.patient.sideBarPatEdit.pastHistory.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navMonitoring"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.monitoring, patId)
              )}
              onClick={() =>
                history.push(
                  getPatientSidebarRoute(routesPatients.monitoring, patId)
                )
              }
              text={labels.patient.sideBarPatEdit.monit.toUpperCase()}
            />
          </Grid>
          {isAlertEnabled && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navAlerts"}
                textVariant={getTextVariant(
                  true,
                  getPatientSidebarRoute(routesPatients.alerts, patId)
                )}
                onClick={() =>
                  history.push(
                    getPatientSidebarRoute(routesPatients.alerts, patId)
                  )
                }
                text={labels.patient.sideBarPatEdit.alerts.toUpperCase()}
              />
            </Grid>
          )}
          {isMedicalNotesEnabled && medicalNotesPermission && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navMedicalNotes"}
                textVariant={getTextVariant(
                  true,
                  getPatientSidebarRoute(routesPatients.medicalNotes, patId)
                )}
                onClick={() =>
                  history.push(
                    getPatientSidebarRoute(routesPatients.medicalNotes, patId)
                  )
                }
                text={labels.patient.sideBarPatEdit.medicalNotes.toUpperCase()}
              />
            </Grid>
          )}
          {(injuryEnabled || illnessEnabled) && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navInjuries"}
                textVariant={
                  !location.pathname.includes(
                    getPatientSidebarRoute(
                      routesPatients.presentationsIllness,
                      patId
                    )
                  ) && `sidebarItem`
                }
                onClick={() =>
                  history.push(
                    getPatientSidebarRoute(
                      routesPatients.presentationsIllness,
                      patId
                    )
                  )
                }
                text={labels.patient.sideBarPatEdit.pres.toUpperCase()}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navActivityPlan"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.plan, patId)
              )}
              onClick={() =>
                history.push(getPatientSidebarRoute(routesPatients.plan, patId))
              }
              text={labels.patient.sideBarPatEdit.viewPlan.toUpperCase()}
            />
          </Grid>
          {isStatisticsEnabled && statisticsPermission && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navStatistics"}
                textVariant={getTextVariant(
                  statisticsPermission,
                  getPatientSidebarRoute(routesPatients.statistics, patId)
                )}
                onClick={() =>
                  statisticsPermission &&
                  history.push(
                    getPatientSidebarRoute(routesPatients.statistics, patId)
                  )
                }
                text={labels.patient.sideBarPatEdit.statistics.toUpperCase()}
              />
            </Grid>
          )}
          {isAnalyticsEnabled && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navAnalytics"}
                textVariant={getTextVariant(
                  analyticsPermission,
                  getPatientSidebarRoute(routesPatients.report, patId)
                )}
                onClick={() =>
                  analyticsPermission &&
                  history.push(
                    getPatientSidebarRoute(routesPatients.report, patId)
                  )
                }
                text={labels.patient.sideBarPatEdit.graphReport.toUpperCase()}
              />
            </Grid>
          )}
          {isChartEnabled && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navAnalytics"}
                textVariant={getTextVariant(
                  true,
                  getPatientSidebarRoute(routesPatients.controlRoom, patId)
                )}
                onClick={() =>
                  history.push(
                    getPatientSidebarRoute(routesPatients.controlRoom, patId)
                  )
                }
                text={labels.patient.sideBarPatEdit.controlRoom.toUpperCase()}
              />
            </Grid>
          )}
          {isStatisticsAnalyticsEnabled && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navAnalytics"}
                textVariant={getTextVariant(
                  analyticsPermission,
                  getPatientSidebarRoute(
                    routesPatients.statisticsAnalytics,
                    patId
                  )
                )}
                onClick={() =>
                  analyticsPermission &&
                  history.push(
                    getPatientSidebarRoute(
                      routesPatients.statisticsAnalytics,
                      patId
                    )
                  )
                }
                text={labels.patient.sideBarPatEdit.statisticsAnalytics.toUpperCase()}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navFeedback"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.controlQuest, patId)
              )}
              onClick={() =>
                history.push(
                  getPatientSidebarRoute(routesPatients.controlQuest, patId)
                )
              }
              text={labels.patient.sideBarPatEdit.controlQuest.toUpperCase()}
            />
          </Grid>
          {isMedicalResourcesEnabled && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navResources"}
                textVariant={getTextVariant(
                  true,
                  getPatientSidebarRoute(routesPatients.medicalResource, patId)
                )}
                onClick={() =>
                  history.push(
                    getPatientSidebarRoute(
                      routesPatients.medicalResource,
                      patId
                    )
                  )
                }
                text={labels.patient.sideBarPatEdit.medicalRis.toUpperCase()}
              />
            </Grid>
          )}
          {isPhysioResourcesEnabled && (
            <Grid item xs={12}>
              <SpIconAndTextButton
                id={"navResources"}
                textVariant={getTextVariant(
                  true,
                  getPatientSidebarRoute(routesPatients.physioResource, patId)
                )}
                onClick={() =>
                  history.push(
                    getPatientSidebarRoute(routesPatients.physioResource, patId)
                  )
                }
                text={labels.patient.sideBarPatEdit.phisioRis.toUpperCase()}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navTransfer"}
              textVariant={getTextVariant(
                true,
                getPatientSidebarRoute(routesPatients.transfer, patId)
              )}
              onClick={() =>
                history.push(
                  getPatientSidebarRoute(routesPatients.transfer, patId)
                )
              }
              text={labels.patient.sideBarPatEdit.transfPat.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12}>
            <SpIconAndTextButton
              id={"navDelete"}
              textVariant={`sidebarItem`}
              onClick={() => setOpenDeletePatient(true)}
              text={labels.patient.sideBarPatEdit.deletePatient.toUpperCase()}
            />
          </Grid>
        </SidebarNavColumn>
      </Drawer>

      <SpDialog
        style={{ padding: "1%" }}
        open={openDeletePatient}
        setOpen={setOpenDeletePatient}
        title={labels.patient.sideBarPatEdit.deletePatientDialogTitle}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText id={"dialogContent"} variant="text">
              {labels.patient.sideBarPatEdit.deletePatientDialogContent}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                id={"dialogButtonYes"}
                text={labels.groups.groupDetail.deletePatientDialog.yes}
                buttonType="accept"
                onClick={removePatient}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                id={"dialogButtonNo"}
                text={labels.groups.groupDetail.deletePatientDialog.no}
                buttonType="accept"
                onClick={() => setOpenDeletePatient(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default PatientsEditSidebar;
