import Grid from "@material-ui/core/Grid";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import { labels, psTranslate } from "../../../shared/translations";
import moment from "moment";
import {
  testTemplateHelper,
  checkZScore,
  getDataChartByFilters,
  getResults,
  getTableDataTest,
  getTableDataFromList,
  getTableDataWorkload,
  HeaderButton,
  updateDataMeasurementFunction,
  updateDataPROMSFunction,
  updatePreferences,
  createExcel,
} from "./PatientsStatisticsAnalyticsFiltersHelper";
import { getAllActivitiesTypes } from "../../../../models/actions/Activity";
import { getActivityFeedbackParameters } from "../../../../models/actions/Activity";
import { getActivityFeedbacksInRange } from "../../../../models/actions/CalendarActivity";

import {
  findPatientByPk,
  getCharts,
  getGroupsSameGroupOf,
  getMeasurementsParameters,
  getPatientsNames,
  getPatientsNamesSameGroupOf,
  getTimeViews,
} from "../../../../models/actions/Patients";
import { getAllPromsNames } from "../../../../models/actions/Proms";
import { ActivityFilters } from "./ActivityFilters";
import { AthleteFilters } from "./AthleteFilters";
import { AdvancedFilters } from "./AdvancedFilters";
import SpIconButton from "../../../../components/atoms/SpIconButton";
import { getStatisticAnalyticProfessional } from "../../../../models/actions/Professionals";
import TuneIcon from "@material-ui/icons/Tune";
import SpButton from "../../../../components/atoms/SpButton";
import {
  getAssessmentCount,
  getAssessmentTemplatesByProfessional,
} from "../../../../models/actions/Assessment";
import { getGroupsById } from "../../../../models/actions/Groups";
import { theme } from "../../../../components/theme";

const PatientsStatisticsAnalyticsFilters = ({
  filters,
  setFilters,
  data,
  setData,
  setLoading,
  ...props
}) => {
  const { patId, groupId } = useParams();

  const [loadingData, setLoadingData] = useState(true);
  const [showFilters, setShowFilters] = useState(true);
  const [oldFilters, setOldFilters] = useState(filters);
  const [mainPatientKey, setMainPatientKey] = useState("");
  const [measurementsMapping, setMeasurementsMapping] = useState(null);

  // use callback per non ricalcolare in futuro gli stessi valori che cambiano solo in base al patId
  const fetchInitial = useCallback(async () => {
    // tutte le attività (allenamento, esercizio ecc.)
    const returnActivities = await getAllActivitiesTypes();
    let unlinkedPatients = await getPatientsNames();
    if (patId)
      unlinkedPatients = unlinkedPatients.filter((pat) => pat.id !== patId);

    // pazienti del gruppo
    let linkedPatients = [];
    if (patId)
      linkedPatients = await getPatientsNamesSameGroupOf({
        id_patient: patId,
      });
    linkedPatients.forEach((pat) => {
      pat.groupByKey = pat.group.name;
    });
    // tutti i grafici disponibili (linear ecc.)
    const fetchedGraphTypes = await getCharts();
    // tutti i gruppi di un paziente
    let groups = [];
    if (patId) groups = await getGroupsSameGroupOf({ id_patient: patId });
    // tutte le viste disponibili (daily ecc.)
    const fetchedDateViews = await getTimeViews();
    let patient = {};

    if (groupId) {
      const groupResults = await getGroupsById({ id_group: groupId });
      groups = [
        {
          id: groupResults.id,
          name: groupResults.name,
          patient_groups: groupResults.patients,
          professional_groups: groupResults.professionals,
        },
      ];
      linkedPatients = groupResults.patients.map(
        ({ familyName, givenName, id }) => ({
          group: { id: groupId, name: groupResults?.name },
          groupByKey: groupResults?.name,
          id: id,
          name: `${givenName} ${familyName}`,
        })
      );
    }

    if (patId) patient = await findPatientByPk({ userId: patId });
    const newMainPatientKey = patId
      ? `${patient.givenName} ${patient.familyName}` ?? ""
      : `${labels.analytics.average} ${groups[0].name ?? ""}`;
    setMainPatientKey(newMainPatientKey);

    return {
      returnActivities,
      unlinkedPatients,
      linkedPatients,
      groups,
      fetchedDateViews,
      fetchedGraphTypes,
    };
  }, [patId, groupId]);

  // fetch data viene richiamato ogni volta che cambiano i filtri
  const fetchData = async () => {
    // fetchinitial viene richiamato SOLO all'inizio perchè dipende
    // esclusivamente dall'utente selezionato
    setLoading(true);
    setLoadingData(true);
    const {
      returnActivities,
      unlinkedPatients,
      linkedPatients,
      groups,
      fetchedDateViews,
      fetchedGraphTypes,
    } = await fetchInitial();
    const oldFilters = await getStatisticAnalyticProfessional({
      id_patient: patId,
      id_group: groupId,
    });

    const getProms = async (startDate) => {
      // tutti i proms
      let bodyProms = {};
      if (patId)
        bodyProms = {
          id_patient: patId,
          start_date: startDate,
          end_date: moment().format("YYYY-MM-DD"),
        };

      if (groupId)
        bodyProms = {
          start_date: startDate,
          end_date: moment().format("YYYY-MM-DD"),
        };
      return await getAllPromsNames(bodyProms);
    };

    const getTests = async (startDate) => {
      let body = {};

      if (patId)
        body = {
          id_patient: patId,
          start_date: startDate,
          end_date: moment().format("YYYY-MM-DD"),
        };

      if (groupId)
        body = {
          start_date: startDate,
          end_date: moment().format("YYYY-MM-DD"),
        };
      return await getAssessmentTemplatesByProfessional(body);
    };

    const getMeasurements = async (startDate) => {
      //  tutte le misurazioni
      let body = {};
      if (patId)
        body = {
          id_patients: [patId],
          start_date: startDate,
          end_date: moment().format("YYYY-MM-DD"),
        };
      if (groupId)
        body = {
          id_patients: groups
            .map(({ patient_groups }) => patient_groups.map(({ id }) => id))
            .flat(),
          start_date: startDate,
          end_date: moment().format("YYYY-MM-DD"),
        };
      const measurements = await getMeasurementsParameters(body);
      setMeasurementsMapping(
        measurements.reduce((accum, mapping) => {
          accum[mapping.id] = `${psTranslate(
            mapping.category_name
          )} - ${psTranslate(mapping.element_name)} (${psTranslate(
            mapping.column_name
          )})`;
          return accum;
        }, {})
      );

      return measurements;
    };

    if (oldFilters) {
      const previousFilters = { ...JSON.parse(oldFilters.data) };
      const assessmentsTemplates = await getTests(previousFilters.startDate);
      const allProms = await getProms(previousFilters.startDate);
      const allMeasurements = await getMeasurements(previousFilters.startDate);
      // filtro tutti i parametri sulla base di quelli ammessi
      setFilters({
        ...filters,
        startDate: previousFilters.startDate,
        activity: {
          ...filters.activity,
          activities: returnActivities,
          selectedActivites: previousFilters.activity.selectedActivites,
          studyParamsPROMS: allProms,
          selectedDateView: previousFilters.activity.selectedDateView,
          dateView: previousFilters.activity.dateView,
          measurements: allMeasurements,

          selectedMeasurements: previousFilters.activity.selectedMeasurements,
          selectedStudyParams: previousFilters.activity.selectedStudyParams,
          selectedStudyParamsPROMS:
            previousFilters.activity.selectedStudyParamsPROMS,
          assessmentsTemplates: assessmentsTemplates,
          selectedAssessmentsTemplates:
            previousFilters.activity.selectedAssessmentsTemplates,
        },
        graph: {
          ...filters.graph,
          graphType: fetchedGraphTypes,
          selectedGraphType: fetchedGraphTypes.find(
            ({ key }) => key === "LINEAR"
          ),
        },
        comparison: {
          ...filters.comparison,
          unlinkedPatients: unlinkedPatients,
          linkedPatients: linkedPatients,
          groupsPatients: groups,
          selectedUnlinkedPatients:
            previousFilters.comparison.selectedUnlinkedPatients,
          selectedLinkedPatients:
            previousFilters.comparison.selectedLinkedPatients,
          selectedGroupsPatients:
            previousFilters.comparison.selectedGroupsPatients,
        },
        workloadStudy: {
          ...filters.workloadStudy,
          ...previousFilters.workloadStudy,
        },
        promsStudy: { ...filters.promsStudy, ...previousFilters.promsStudy },
        measurementsStudy: {
          ...filters.measurementsStudy,
          ...previousFilters.measurementsStudy,
        },
      });
    } else {
      const assessmentsTemplates = await getTests(filters.startDate);
      const allProms = await getProms(filters.startDate);
      const allMeasurements = await getMeasurements(filters.startDate);
      const newFiltres = {
        ...filters,
        activity: {
          ...filters.activity,
          activities: returnActivities,
          selectedActivites: returnActivities,
          studyParamsPROMS: allProms,
          selectedDateView: fetchedDateViews[0],
          dateView: fetchedDateViews,
          measurements: allMeasurements,
          assessmentsTemplates: assessmentsTemplates,
        },
        graph: {
          ...filters.graph,
          graphType: fetchedGraphTypes,
          selectedGraphType: fetchedGraphTypes.find(
            ({ key }) => key === "LINEAR"
          ),
        },
        comparison: {
          ...filters.comparison,
          unlinkedPatients: unlinkedPatients,
          linkedPatients: linkedPatients,
          selectedGroupsPatients: groups,
          groupsPatients: groups,
        },
      };

      if (groupId)
        newFiltres["comparison"]["selectedLinkedPatients"] = linkedPatients;

      setFilters(newFiltres);
    }
    setLoadingData(false);
  };

  const updateActivities = async () => {
    let studyParams = await getActivityFeedbackParameters();

    let body = {
      id_activity_types: filters.activity.selectedActivites.map(({ id }) => id),
      startDate: `${moment(filters.startDate).format("YYYY-MM-DD")}`,
      endDate: `${moment().format("YYYY-MM-DD")}`,
    };

    if (patId) body["ids_patient"] = [patId];
    if (groupId) body["id_group"] = [groupId];

    const inRange = await getActivityFeedbacksInRange(body);

    // filtro tutti i parametri sulla base di quelli ammessi
    let studyParamsAllowed = [];
    inRange.forEach((key) => {
      let find = studyParams.find(({ id }) => id === key);
      if (find) studyParamsAllowed.push(find);
    });
    const newSelectedStudyParams = filters.activity.selectedStudyParams.filter(
      ({ id }) => studyParamsAllowed.map(({ id }) => id).includes(id)
    );
    setFilters({
      ...filters,
      activity: {
        ...filters.activity,
        selectedStudyParams: newSelectedStudyParams,
        studyParams: studyParamsAllowed,
      },
    });
  };

  useEffect(() => {
    updateActivities();
  }, [filters.activity.selectedActivites, filters.startDate, filters.endDate]);

  const updateDataPROMS = async () => {
    let { groupData, newPromsGraphData } = await updateDataPROMSFunction({
      filters: filters,
      patId: patId,
    });

    groupData = groupData ? groupData : {};
    newPromsGraphData = newPromsGraphData ? newPromsGraphData : {};
    return { ...groupData, ...newPromsGraphData };
  };

  const updateDataMeasurement = async () => {
    let { groupData, newMeasurementGraphData } =
      await updateDataMeasurementFunction({
        filters,
        patId,
      });
    const dataTable = getTableDataTest(
      {
        TEMPORAL: { Line: { ...newMeasurementGraphData, ...groupData } },
      },
      filters.activity.selectedMeasurements
    );
    setData({
      ...data,
      startDate: filters.startDate,
      endDate: filters.endDate,
      testData: {
        TEMPORAL: { Line: { ...newMeasurementGraphData, ...groupData } },
      },
      dataTableMeasurement: dataTable,
    });
    groupData = groupData ? groupData : {};
    newMeasurementGraphData = newMeasurementGraphData
      ? newMeasurementGraphData
      : {};
    return { ...groupData, ...newMeasurementGraphData };
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getResultsFunction = async ({ isRace }) => {
    const result = await getResults({
      filters: {
        ...filters,
        activity: {
          ...filters.activity,
          selectedActivites: isRace
            ? filters.activity.activities.filter(({ key }) => key === "race")
            : filters.activity.selectedActivites,
        },
      },
      patId,
      patients: [
        ...filters.comparison.selectedUnlinkedPatients,
        ...filters.comparison.selectedLinkedPatients,
      ],
      groups: filters.comparison.selectedGroupsPatients,
    });
    return result;
  };

  const getResultsCallback = async () => {
    const allNewPaitents = [
      ...filters.comparison.selectedUnlinkedPatients,
      ...filters.comparison.selectedLinkedPatients,
    ].map(({ id }) => id);
    const assessmentsCountsTemp = await getAssessmentCount({
      id_group: groupId,
      id_patients:
        allNewPaitents.length > 0 ? [...allNewPaitents, patId] : patId,
      startDate: `${moment(moment().diff(1, "year")).format("YYYY-MM-DD")}`,
      endDate: `${moment(moment().add(1, "day")).format("YYYY-MM-DD")}`,
    });
    return assessmentsCountsTemp;
  };

  const updateData = async () => {
    setLoading(true);
    if (!loadingData) {
      try {
        const result = await getResultsFunction({ isRace: false });
        const resultRace = await getResultsFunction({ isRace: true });
        const proms = await updateDataPROMS();
        const test = await updateDataMeasurement();
        let keys = [];
        if (Object.keys(proms).length > 0)
          keys = Object.keys(proms[Object.keys(proms)[0]]).map(
            (item) => item.split("-")[0]
          );
        keys = new Set(keys);

        let promasArray = [];

        keys.forEach((key) => {
          const patients = Object.keys(proms);
          let result = {};
          patients.forEach((patient) => {
            result[patient] = {};
            Object.keys(proms[patient]).forEach((item) => {
              if (item.includes(key)) {
                result[patient] = {
                  ...result[patient],
                  [item]: proms[patient][item],
                };
              }
            });
          });
          promasArray.push(result);
        });

        let testsCountsTemp = await getResultsCallback();

        // TESTS
        const resultTest = await testTemplateHelper({
          patients: [
            ...filters.comparison.selectedUnlinkedPatients,
            ...filters.comparison.selectedLinkedPatients,
          ],
          assessmentsCounts: testsCountsTemp,
          selectedAssessmentsTemplates:
            filters.activity.selectedAssessmentsTemplates,
          measurementsMapping,
          patId,
          filters,
          study: filters.measurementsStudy,
          type: "measurement",
          mainSubjectKey: mainPatientKey,
        });

        // in ingresso vengono passati i filtri e i dati della timeline
        // cosi poi in base agli studi attivi vengono calcolati i risultati
        const dataChartFilteres = getDataChartByFilters({
          study: filters.workloadStudy,
          filters,
          result,
          type: "workload",
          mainSubjectKey: mainPatientKey,
        });
        const dataChartZscoreFilteres = getDataChartByFilters({
          study: checkZScore({ study: filters.workloadStudy }),
          filters,
          result,
          type: "workload",
          mainSubjectKey: mainPatientKey,
        });
        const dataChartFilteresRace = getDataChartByFilters({
          study: filters.workloadStudy,
          filters,
          result: resultRace,
          type: "workload",
          mainSubjectKey: mainPatientKey,
        });
        const dataChartZscoreFilteresRace = getDataChartByFilters({
          study: checkZScore({ study: filters.workloadStudy }),
          filters,
          result: resultRace,
          type: "workload",
          mainSubjectKey: mainPatientKey,
        });
        const dataChartFilteresPromsList = promasArray.map((prom) =>
          getDataChartByFilters({
            study: filters.promsStudy,
            filters,
            result: prom,
            type: "proms",
            mainSubjectKey: mainPatientKey,
          })
        );
        const dataChartFilteresPromsScoreList = promasArray.map((prom) =>
          getDataChartByFilters({
            study: checkZScore({ study: filters.promsStudy }),
            filters,
            result: prom,
            type: "proms",
            mainSubjectKey: mainPatientKey,
          })
        );
        const dataChartFilteresTest = getDataChartByFilters({
          study: filters.measurementsStudy,
          filters,
          result: test,
          type: "measurement",
          mainSubjectKey: mainPatientKey,
        });
        const dataChartFilteresTestZscore = getDataChartByFilters({
          study: checkZScore({ study: filters.measurementsStudy }),
          filters,
          result: test,
          type: "measurement",
          mainSubjectKey: mainPatientKey,
        });

        const dataTablezscoreTest = Object.keys(resultTest).map((item) =>
          getDataChartByFilters({
            study: checkZScore({ study: filters.measurementsStudy }),
            filters,
            result: resultTest[item]["TEMPORAL"]["Line"],
            type: "measurement",
            mainSubjectKey: mainPatientKey,
          })
        );

        const dataTable = getTableDataWorkload(dataChartFilteres, filters);
        const dataTableRace = getTableDataWorkload(
          dataChartFilteresRace,
          filters
        );

        const dataTableTestold = getTableDataTest(
          dataChartFilteresTest,
          filters.activity.selectedMeasurements
        );
        const dataTablePROMS = dataChartFilteresPromsList.map((item) =>
          getTableDataFromList(item)
        );
        const dataTableTest = Object.keys(resultTest).map((item) =>
          getTableDataFromList(resultTest[item])
        );

        setData({
          ...data,
          startDate: filters.startDate,
          endDate: filters.endDate,
          dataChart: dataChartFilteres,
          dataZscore: dataChartZscoreFilteres,
          dataChartRace: dataChartFilteresRace,
          dataZscoreRace: dataChartZscoreFilteresRace,
          dataTable: dataTable,
          dataTableRace: dataTableRace,
          promsData: dataChartFilteresPromsList,
          dataTablePROMS: dataTablePROMS,
          dataPROMSzscore: dataChartFilteresPromsScoreList,
          testData: dataChartFilteresTest,
          dataTableMeasurement: dataTableTestold,
          dataMeasurementZscore: dataChartFilteresTestZscore,
          testList: resultTest,
          testTableList: dataTableTest,
          testZscoreList: dataTablezscoreTest,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  // aggiorno i dati del grafico se cambiano i filtri oppure
  // le date di studio
  useEffect(() => {
    if (oldFilters !== filters) {
      setOldFilters(filters);
      updateData();
    }
  }, [
    filters.startDate,
    filters.endDate,
    filters.activity.selectedMeasurements,
    filters.activity.selectedStudyParams,
    filters.activity.selectedStudyParamsPROMS,
    filters.activity.selectedDateView,
    filters.activity.selectedAssessmentsTemplates,
    filters.comparison.selectedUnlinkedPatients,
    filters.comparison.selectedLinkedPatients,
    filters.comparison.selectedGroupsPatients,
    filters.workloadStudy,
    filters.promsStudy,
    filters.measurementsStudy,
    loadingData,
  ]);

  return (
    <Grid contaier xs={12} direction="row" style={{ paddingRight: "2em" }}>
      <Grid
        container
        direction="row"
        xs={12}
        style={{ paddingTop: "8px", paddingLeft: "8px" }}
      >
        <Grid
          item
          xs={0.5}
          style={{
            backgroundColor: showFilters ? theme.colors.primary.lightBlue : "",
            border:
              !showFilters && `1px solid ${theme.colors.primary.lightBlue}`,
          }}
        >
          <SpIconButton
            alignSelf="flex-start"
            style={{
              color: showFilters ? "#ffffff" : theme.colors.primary.lightBlue,
            }}
            variant={"lightBlue"}
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            <TuneIcon />
          </SpIconButton>
        </Grid>

        {showFilters && (
          <>
            <HeaderButton
              setFilters={setFilters}
              filters={filters}
              buttonName={"activity"}
              buttonLabel={labels.patient.graphReport.filtersButton.activity}
            />

            <HeaderButton
              setFilters={setFilters}
              filters={filters}
              buttonName={"athleteComparison"}
              buttonLabel={labels.patient.graphReport.filtersButton.patients}
            />

            {/* <HeaderButton
              setFilters={setFilters}
              filters={filters}
              buttonName={"graph"}
              buttonLabel={labels.patient.graphReport.filtersButton.char}
            /> */}

            <HeaderButton
              setFilters={setFilters}
              filters={filters}
              buttonName={"advanced"}
              buttonLabel={labels.patient.graphReport.filtersButton.advanced}
            />
            <Grid
              container
              style={{
                justifyContent: "flex-end",
              }}
            >
              <SpButton
                variant="none"
                buttonType={"accept"}
                style={{ paddingBottom: "8px" }}
                onClick={async () => {
                  setLoading(true);
                  const res = await updatePreferences({
                    id_patient: patId,
                    id_group: groupId,
                    data: filters,
                  });
                  props.snackbarShowMessage(res.message);
                  setLoading(false);
                }}
                text={labels.analytics.save}
              />
              <SpButton
                variant="none"
                style={{ paddingBottom: "8px" }}
                onClick={() => {
                  createExcel({ data: data, filters: filters });
                }}
                text={"Download excel"}
              />
            </Grid>
            <div
              style={{
                border: `solid 1px ${theme.colors.primary.lightBlue}`,
                width: "100%",
                padding: "1em",
              }}
            >
              {/* activity filters */}
              {filters.selected === "activity" && (
                <ActivityFilters filters={filters} setFilters={setFilters} />
              )}

              {/* Athlete comparison filters */}
              {filters.selected === "athleteComparison" && (
                <AthleteFilters filters={filters} setFilters={setFilters} />
              )}

              {/* graph filters */}
              {/* {filters.selected === "graph" && (
                <GraphFilters setFilters={setFilters} filters={filters} />
              )} */}

              {/* study filters */}
              {filters.selected === "advanced" && (
                <AdvancedFilters filters={filters} setFilters={setFilters} />
              )}
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default withSnackbar(PatientsStatisticsAnalyticsFilters);
