import {
  getActivityFeedbackParameters,
  getAllActivitiesTypes,
} from "../../../../models/actions/Activity";
import moment from "moment";
import {
  getAllPromsNames,
  getAllPromsSurveyAnswers,
} from "../../../../models/actions/Proms";
import { getActivityResponseParameterFeedbacks } from "../../../../models/actions/Patients";
import { getActivityFeedbacksInRange } from "../../../../models/actions/CalendarActivity";
import {
  averageGraphData,
  processPatientData,
} from "../patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFiltersHelper";
import { labels } from "../../../shared/translations";

export const mapTestToTable = (inputObject) => {
  const resultArray = [];
  if (inputObject && Object.keys(inputObject) > 0) {
    const dates = Object.keys(inputObject[Object.keys(inputObject)[0]]);
    for (const date of dates) {
      const resultItem = { Date: date };
      for (const testName in inputObject) {
        resultItem[testName] = inputObject[testName][date];
      }
      resultArray.push(resultItem);
    }
  }
  return resultArray;
};
