import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { SpStepper } from "../../components/bundles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { findPatientByPk } from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { Grid } from "@material-ui/core";
import PastHistorySumup from "./patientPastHistorySteps/PastHistorySumup";
import PastHistoryInputStep from "./patientPastHistorySteps/PastHistoryInputStep";
import PathologiesInputStep from "./patientPastHistorySteps/PathologiesInputStep";
import { getUserPermission, isFeatureFlagEnabled } from "../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  paddingTop: "3%",
});

const PatientsPastHistory = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [steps, setSteps] = useState([]);
  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { control, register, errors } = useForm({
    shouldUnregister: false,
  });

  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    try {
      const temp = labels.patient.pastHistory.steps.filter(
        ({ key }) => key !== "previousInjuries"
      );
      const responseDetail = await findPatientByPk({ userId: patId });
      const previousInjuries = await isFeatureFlagEnabled("previousinjuries");

      const tempPrevInjuriesPermission = await getUserPermission(
        "previous-injuries",
        getAccessTokenSilently
      );

      if (previousInjuries && tempPrevInjuriesPermission) {
        setSteps(labels.patient.pastHistory.steps);
      } else {
        setSteps(temp);
      }
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  const titles = steps.map(({ key, name }) => <div id={key}>{name}</div>);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        container
        spacing={2}
        direction="row"
        style={{ paddingRight: 0 }}
      >
        <Grid item xs={2} style={{ margin: "auto 0", padding: 0 }}>
          <PatientsEditSidebar
            props={props}
            patId={patId}
            history={history}
            currentPatient={currentPatient}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingRight: 0 }}>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <SpText variant="h1PageTitle">
                {labels.patient.pastHistory.title.toUpperCase()}
              </SpText>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={2} direction="row">
            <StyledGridContainer container spacing={1}>
              <StyledBodyColumn>
                <SpStepper
                  titles={titles}
                  stepperControls={StepperControls(errors)}
                >
                  <PastHistoryInputStep register={register} control={control} />
                  <PathologiesInputStep
                    id={"pashHistoryButton"}
                    register={register}
                    control={control}
                    setLoading={setLoading}
                  />
                </SpStepper>
              </StyledBodyColumn>
            </StyledGridContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsPastHistory);
