import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpCheckbox from "../../components/atoms/SpCheckbox";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { Divider, Grid } from "@material-ui/core";
import GroupEditSidebar from "./shared/GroupEditSidebar";
import { DeleteForever } from "@material-ui/icons";
import {
  fetchPatAnswersToQuest,
  removeFeedbackById,
  saveControlQuestionnaires,
} from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import {
  deleteGroupProms,
  getAllPromsSurveys,
  getFinalScore,
  getGroupProms,
} from "../../models/actions/Proms";
import SpDialog from "../../components/atoms/SpDialog";
import { isFeatureFlagEnabled, titleCase } from "../../utils/common";
import { getGroupsById } from "../../models/actions/Groups";

import { FilterAutocomplete } from "../patients/patientsStatisticsAnalytics/patientsStatisticsAnalyticsComponents/PatientsStatisticsAnalyticsFiltersHelper";
import { theme } from "../../components/theme";
import PatientsMonitoringDetailProms from "../patients/patientsMonitoring/PatientsMonitoringDetailProms";
import PatientsControlQuestionHelper from "./../patients/PatientControlQuestionHelper";
import moment from "moment-timezone";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { isMobile } from "react-device-detect";
import SpIconButton from "../../components/atoms/SpIconButton";

const GroupProms = (props) => {
  const [promsQuestList, setPromsQuestList] = useState([]);
  const [promSelected, setPromSelected] = useState(null);
  const [dialogDetailOpen, setDialogDetailOpen] = useState(false);
  const [selectedRecordDetail, setSelectedRecordDetail] = useState(null);
  const [openDeleteFeedbackDialog, setOpenDeleteFeedbackDialog] =
    useState(false);
  const [feedbackToRemove, setFeedbackToRemove] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [isProfessionalNotifEnabled, setIsProfessionalNotifEnabled] =
    useState(false);
  const [patients, setPatients] = useState([]);
  const [currentGroup, setCurrentGroup] = useState([]);
  const [selectedProms, setSelectedProms] = useState();
  const [detailPresentationProms, setDetailPresentationProms] = useState(false);
  const [addPresentationProms, setAddPresentationProms] = useState(false);

  const [selectedDays, setSelectedDays] = useState([]);
  const [promsScheduled, setPromsScheduled] = useState([]);
  const [selectedProm, setSelectedProm] = useState({});
  const [openDeleteProm, setOpenDeleteProm] = useState(false);

  const { groupId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { control, register, handleSubmit, getValues, reset, setValue } =
    useForm({
      defaultValues: {
        proms: null,
        frequency: null,
        duration: null,
        start_time: null,
      },
      shouldUnregister: false,
    });

  const removeFeedback = async () => {
    try {
      const result = await removeFeedbackById({
        id_questionnaire: feedbackToRemove.id,
      });
      if (result.message) {
        setOpenDeleteFeedbackDialog(false);
        props.snackbarShowMessage(result.message);
        await fetchData();
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveControlsQuestionnaires = async () => {
    try {
      if (selectedDays.length === 0) {
        props.snackbarShowErrorMessage(
          labels.patient.controlQuestions.insertAllData
        );
        return;
      }
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // get email's patient
      const saveResponse = await saveControlQuestionnaires({
        id_patients: patients.map(({ id }) => id),
        id_questionnaires: promSelected,
        frequency: getValues().frequency,
        days: selectedDays.map(({ id }) => id),
        monitoring_questions: promSelected.is_monit_question,
        emails: sendNotification ? patients.map(({ email }) => email) : null,
        start_time: moment(getValues().start_time, "HH:mm").format("HH:mm"),
        start_date: getValues().start_date,
        end_date: getValues().end_date,
        id_group: groupId,
        days_of_week: selectedDays.map(({ key }) => key),
        tz: timeZone,
      });
      if (saveResponse?.message) {
        props.snackbarShowMessage(saveResponse.message);
        reset({
          proms: null,
          frequency: null,
        });
        setSelectedDays([]);
        setPromSelected(null);
        setPromsScheduled(saveResponse?.result);
      } else {
        props.snackbarShowErrorMessage(saveResponse.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    const groupResults = await getGroupsById({ id_group: groupId });
    setCurrentGroup(groupResults);
    setPatients(groupResults.patients);

    // controllo se la feature flag é abilitata
    let isEnabled = await isFeatureFlagEnabled("professional_notification");
    setIsProfessionalNotifEnabled(isEnabled);
    try {
      setLoading(true);
      const temp = await getGroupProms({ id_group: groupId });
      setPromsScheduled(temp);
      const resultProms = await getAllPromsSurveys();

      setPromsQuestList([...resultProms]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const closeAssessmentAdd = () => {
    setAddPresentationProms(false);
  };

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "10px" }}>
      <SpText variant={"text"}>{props.label}</SpText>
      <SpText variant={"h4ComponentLabelRegular"}>{props.value}</SpText>
    </Grid>
  );

  const headCellsControlQuestions = [
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "PROMs",
      isAction: false,
    },
    {
      id: "start_date",
      numeric: false,
      disablePadding: false,
      label: labels.patient.graphReport.section.feedback.filters.start_date,
      isAction: false,
    },
    {
      id: "end_ate",
      numeric: false,
      disablePadding: false,
      label: labels.patient.graphReport.section.feedback.filters.end_date,
      isAction: false,
    },
    {
      id: "days",
      numeric: false,
      disablePadding: false,
      label: labels.patient.controlQuestions.daysOfWeek,
      isAction: false,
    },
    {
      id: "time",
      numeric: false,
      disablePadding: false,
      label: labels.patient.controlQuestions.time,
      isAction: false,
    },
    {
      id: "delete",
      numeric: false,
      disablePadding: false,
      label: "",
      isAction: true,
    },
  ];

  const ControlQuestionsRow = ({ row, index }) => {
    let timeUtc = row.time;

    return (
      <>
        <SpTableRow key={row.id} tabIndex={-1}>
          <SpTableCell style={{ cursor: "pointer" }}>
            <SpText variant="tableText">{psTranslate(row?.prom?.name)}</SpText>
          </SpTableCell>
          <SpTableCell style={{ cursor: "pointer" }}>
            <SpText variant="tableText">
              {moment(row.start_date).format("DD/MM/YYYY")}
            </SpText>
          </SpTableCell>
          <SpTableCell style={{ cursor: "pointer" }}>
            <SpText variant="tableText">
              {moment(row.end_date).format("DD/MM/YYYY")}
            </SpText>
          </SpTableCell>
          <SpTableCell style={{ cursor: "pointer" }}>
            {row.days_of_week.map((day) => (
              <SpText variant="tableText">
                {titleCase(labels.week.find(({ key }) => key === day)?.name)}
              </SpText>
            ))}
          </SpTableCell>
          <SpTableCell style={{ cursor: "pointer" }}>
            <SpText variant="tableText">{timeUtc}</SpText>
          </SpTableCell>
          <SpTableCell align={"right"}>
            {
              <SpIconButton
                buttonType={"accept"}
                onClick={() => {
                  setSelectedProm(row.id);
                  setOpenDeleteProm(true);
                }}
              >
                <DeleteForever />
              </SpIconButton>
            }
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item container xs={12} spacing={2} direction="row">
          <Grid item xs={12}>
            <GroupEditSidebar
              props={props}
              groupId={groupId}
              history={history}
              currentGroup={currentGroup}
            />
          </Grid>
          {!detailPresentationProms && (
            <Grid item xs={12} spacing={1} container direction="column">
              <Grid item xs={"auto"}>
                <SpText variant="h1PageTitle">
                  {labels.patient.controlQuestions.title.toUpperCase()}
                </SpText>
              </Grid>

              <form onSubmit={handleSubmit(saveControlsQuestionnaires)}>
                <Grid item xs={"auto"} container spacing={1} direction="column">
                  <Grid item xs={4}>
                    <Controller
                      style={{ marginTop: "3%" }}
                      render={(props) => (
                        <SpSelect
                          label={labels.patient.controlQuestions.selectProms.title.toUpperCase()}
                          value={props.value}
                          formControlWidth={520}
                          selectPlaceholder={
                            labels.patient.controlQuestions.selectProms
                              .placeholder
                          }
                          onChange={(e) => {
                            props.onChange(e.target.value);
                            setPromSelected(e.target.value);
                          }}
                        >
                          {promsQuestList.map((p) => (
                            <SpSelectMenuItem key={p.id} value={p.id}>
                              {p.name
                                ? psTranslate(p.name)
                                : labels.patient.controlQuestions.selectProms
                                    .placeholder}
                            </SpSelectMenuItem>
                          ))}
                        </SpSelect>
                      )}
                      defaultValue={""}
                      name={"proms"}
                      control={control}
                    />
                    {isProfessionalNotifEnabled && (
                      <SpCheckbox
                        label={labels.patient.controlQuestions.selectProms.sendNotification.toUpperCase()}
                        rightlabel={true}
                        formControlStyle={{
                          justifyContent: "flex-start",
                          marginTop: "8px",
                        }}
                        checked={sendNotification}
                        onChange={(evnt) => {
                          setSendNotification(evnt.target.checked);
                        }}
                      />
                    )}
                  </Grid>
                  {promSelected && (
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={4}>
                        <FilterAutocomplete
                          placeholder={
                            labels.patient.controlQuestions.selectFrequency
                              .title
                          }
                          value={selectedDays}
                          onChange={(_, newValue) => {
                            setSelectedDays(newValue);
                          }}
                          renderOptions={selectedDays}
                          options={labels.week}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ paddingTop: "0.5em" }}>
                        <SpTextInput
                          style={{ minWidth: 300 }}
                          name="start_date"
                          defaultValue={moment().format("YYYY-MM-DD")}
                          type="date"
                          label={"start date"}
                          placeholder={
                            labels.patient.controlQuestions.selectFrequency
                              .placeholder
                          }
                          inputRef={register}
                        />
                        <SpTextInput
                          style={{ minWidth: 300 }}
                          name="end_date"
                          defaultValue={moment()
                            .add(1, "week")
                            .format("YYYY-MM-DD")}
                          type="date"
                          label={"end date"}
                          placeholder={
                            labels.patient.controlQuestions.selectFrequency
                              .placeholder
                          }
                          inputRef={register}
                        />
                        <SpTextInput
                          style={{ paddingLeft: "2em" }}
                          type="time"
                          name="start_time"
                          min="05:00"
                          max="22:00"
                          defaultValue={moment("08:00", "HH:mm").format(
                            "HH:mm"
                          )}
                          inputRef={register}
                          label={labels.patient.viewPlan.addActivity.inputForm.start_time.toUpperCase()}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {promSelected && (
                    <Grid item xs>
                      <SpButton
                        style={{ minWidth: 300 }}
                        buttonType="accept"
                        type="submit"
                        variant="column"
                        text={labels.patient.controlQuestions.sendQuestionButton.toUpperCase()}
                      />
                    </Grid>
                  )}
                </Grid>

                {promsScheduled?.length > 0 && (
                  <Grid container direction="column" xs={12}>
                    <Grid item xs={12} style={{ marginTop: "2%" }}>
                      <SpText variant="h4ComponentLabel">
                        {labels.patient.controlQuestions.reqiredQuestionnaires}
                      </SpText>
                      <SpTable
                        headCells={headCellsControlQuestions}
                        rows={promsScheduled}
                        pagination={true}
                        rowKey="id"
                        padding={false}
                        notCheckable={false}
                        tableContainerMaxHeight={
                          isMobile
                            ? "calc(100vh - 300px)"
                            : "calc(100vh - 320px)"
                        }
                      >
                        <ControlQuestionsRow />
                      </SpTable>
                    </Grid>
                  </Grid>
                )}

                {patients.map((currentPatient) => {
                  return (
                    <PatientsControlQuestionHelper
                      setLoading={setLoading}
                      patId={currentPatient.id}
                    />
                  );
                })}
              </form>
            </Grid>
          )}
          {detailPresentationProms && (
            <Grid item xs={4} spacing={1} container direction="column">
              <SpButton
                buttonType="accept"
                variant="none"
                style={{ width: "100%" }}
                text={labels.patient.presentation.detail.stepper.step6.close.toUpperCase()}
                onClick={() => setDetailPresentationProms(false)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {detailPresentationProms && (
        <PatientsMonitoringDetailProms
          closeAssessmentAdd={closeAssessmentAdd}
          proms={selectedProms}
          setLoading={setLoading}
        />
      )}
      {/* Dialog detail feedback */}
      <SpDialog
        open={dialogDetailOpen}
        setOpen={setDialogDetailOpen}
        title={labels.patient.controlQuestions.detailDialog.title}
        style={{ marginRight: "60%" }}
      >
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <>
            <Grid item container xs={12} spacing={2}>
              <Grid xs={12}>
                <SpText variant="text">{`${labels.patient.controlQuestions.detailDialog.questTitle}`}</SpText>
              </Grid>
              <Grid xs={12}>
                <SpText variant="h4ComponentLabelRegular">
                  {psTranslate(selectedRecordDetail?.title)}
                </SpText>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Divider
                style={{
                  padding: "1px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  backgroundColor: theme.colors.primary.lightBlue,
                  width: "100%",
                }}
              />

              <Grid xs={12} direction="column" style={{ marginTop: "1%" }}>
                {selectedRecordDetail?.data?.questions?.map((question) => (
                  <LabelValue
                    label={psTranslate(question.question)}
                    value={question?.answers
                      ?.map((a) => psTranslate(a.answer))
                      ?.join(", ")}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        </Grid>
      </SpDialog>
      {/*Dialog delete feedback*/}
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteFeedbackDialog}
        setOpen={setOpenDeleteFeedbackDialog}
        title={labels.patient.controlQuestions.deleteDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.patient.controlQuestions.deleteDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.mylab.dialog.delete.yes}
                buttonType="accept"
                onClick={removeFeedback}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.mylab.dialog.delete.no}
                buttonType="accept"
                onClick={() => setOpenDeleteFeedbackDialog(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>

      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteProm}
        setOpen={setOpenDeleteProm}
        title={labels.patient.controlQuestions.deletePromTitle}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.patient.controlQuestions.deleteProms}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.yes}
                buttonType="accept"
                onClick={async () => {
                  await deleteGroupProms({ id: selectedProm });
                  setOpenDeleteProm(false);
                  await fetchData();
                }}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.no}
                buttonType="accept"
                onClick={() => {
                  setOpenDeleteProm(false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(GroupProms);
