import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { labels } from "../../../pages/shared/translations";
import SpButton from "../../atoms/SpButton";
import SpText from "../../atoms/SpText";
import FeedbackElement from "./FeedbackElement";
import { getCalendarActivityById } from "../../../models/actions/CalendarActivity";

const Feedback = ({ event, setValue, click, register, isPrint }) => {
  const [feedbacks, setFeedbacks] = useState([]);

  const fetchData = async () => {
    const res = await getCalendarActivityById({
      id: event.id,
    });
    if (res.patients.length > 0) setFeedbacks(res.patients[0]);
  };

  useEffect(() => {
    fetchData();
  });

  return (
    feedbacks &&
    feedbacks?.patientFeedbacks?.length > 0 && (
      <Grid
        direction="column"
        container
        xs={12}
        style={{ marginTop: "2%", marginBottom: "2%" }}
      >
        <Grid
          item
          container
          direction="row"
          xs={12}
          style={{ marginBottom: "2%" }}
        >
          <Grid item xs={12}>
            <SpText variant={!isPrint ? "h1PageSubtitle" : "textPrint"}>
              Feedback
            </SpText>
            {!(
              event.archiving_status === "completed_and_feedback_not_sent" ||
              event.archiving_status === "completed_and_feedback_sent"
            ) && (
              <SpText variant={!isPrint ? "text" : "textPrint"}>
                {labels.bundles.calendarPlan.feedback.hint}
              </SpText>
            )}
          </Grid>
        </Grid>
        <FeedbackElement
          feedbacks={feedbacks?.patientFeedbacks}
          event={event}
          setValue={setValue}
          register={register}
          isPrint={isPrint}
        />
        {!(
          event.archiving_status === "completed_and_feedback_not_sent" ||
          event.archiving_status === "completed_and_feedback_sent"
        ) && (
          <Grid xs={12} item>
            <SpButton
              id="saveFeedbacksBtn"
              buttonType="accept"
              style={{ width: "100%", marginTop: "2%" }}
              text={labels.bundles.calendarPlan.feedback.save}
              variant="h1PageTitle"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                click();
              }}
            />
          </Grid>
        )}
      </Grid>
    )
  );
};

export default Feedback;
