import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import { SpStepper } from "../../components/bundles";
import { findPatientByPk } from "../../models/actions/Patients";
import { getUserPermission, useQueryParams } from "../../utils/common";
import { labels } from "../shared/translations";
import PatientsMonitoringStep1 from "./patientsMonitoring/PatientsMonitoringStep1";
import PatientsMonitoringStep2 from "./patientsMonitoring/PatientsMonitoringStep2";
import PatientsMonitoringStep3 from "./patientsMonitoring/PatientsMonitoringStep3";
import PatientsMonitoringStep4 from "./patientsMonitoring/PatientsMonitoringStep4";
import PatientsMonitoringStepPathologies from "./patientsMonitoring/PatientsMonitoringStepPathologies";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { useAuth0 } from "@auth0/auth0-react";

const PatientsMonitoring = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState(null);
  const [monitoringTableEnabled, setMonitoringTableEnabled] = useState(false);
  const [titles, setTitles] = useState([<></>]);

  const query = useQueryParams();
  const initialStep = query.get("assessments") ? 1 : 0;

  useEffect(() => {}, []);
  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { register, errors, control, getValues } = useForm({
    defaultValues: {
      patientName: "",
      birthDate: "",
      phone: "",
      job: "",
    },
    shouldUnregister: false,
  });

  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);

      const monitoringTable = await getUserPermission(
        "monitoring-table",
        getAccessTokenSilently
      );
      setMonitoringTableEnabled(monitoringTable);

      let temptitles = labels.patient.monitoring.steps.map((t, idx) => (
        <StepTitle key={idx}>{t}</StepTitle>
      ));

      if (!monitoringTable)
        temptitles = labels.patient.monitoring.steps
          .filter((item) => item !== "Tabella")
          .map((t, idx) => <StepTitle key={idx}>{t}</StepTitle>);
      setTitles(temptitles);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justyfyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          spacing={2}
          direction="row"
          style={{ height: "100%" }}
        >
          <Grid item xs={2} style={{ margin: "auto 0", padding: 0 }}>
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={12} container direction="row">
            <Grid item xs={12}>
              <SpText variant="h1PageTitle">
                {labels.patient.monitoring.title.toUpperCase()}
              </SpText>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "2em" }}>
              <SpStepper
                initialStep={initialStep}
                titles={titles}
                stepperControls={StepperControls(errors)}
                containerWidth={"92%"}
              >
                <PatientsMonitoringStep1
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                  setLoading={setLoading}
                />
                <PatientsMonitoringStep2
                  {...props}
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                />
                <PatientsMonitoringStep3
                  {...props}
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                />
                {monitoringTableEnabled && (
                  <PatientsMonitoringStep4
                    register={register}
                    control={control}
                    getValues={getValues}
                    patient={currentPatient}
                    setLoading={props.setLoading}
                  />
                )}
              </SpStepper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsMonitoring);
