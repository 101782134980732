import { call } from "./utils";

export const createTrainingTemplate = async (data) =>
  await call({
    url: "/activity/create/training/template",
    data: { data },
  });

export const updateTrainingTemplate = async (data) =>
  await call({
    url: "/activity/update/training/template",
    data: { data },
  });

export const duplicateTrainingTemplate = async (data) =>
  await call({
    url: "/activity/duplicate/training/template",
    data: { data },
  });

export const getAllTrainingTemplates = async () =>
  await call({ url: "/activity/get/training/templates/by/professional" });

export const getTrainingTemplateById = async (data) =>
  await call({
    url: "/activity/get/training/template/by/id",
    data: { data },
  });

export const createProtocolTemplate = async (data) =>
  await call({
    url: "/activity/create/protocol/template",
    data: { data },
  });

export const duplicateProtocolTemplate = async (data) =>
  await call({
    url: "/activity/duplicate/protocol/template",
    data: { data },
  });

export const createRehabTemplate = async (data) =>
  await call({
    url: "/activity/create/rehab/template",
    data: { data },
  });

export const updateProtocolTemplate = async (data) =>
  await call({
    url: "/activity/update/protocol/template",
    data: { data },
  });

export const updateRehabTemplate = async (data) =>
  await call({
    url: "/activity/update/rehab/template",
    data: { data },
  });

export const duplicateRehabTemplate = async (data) =>
  await call({
    url: "/activity/duplicate/rehab/template",
    data: { data },
  });

export const getAllProtocolTemplates = async () =>
  await call({ url: "/activity/get/protocol/templates/by/professional" });

export const getAllAssessmentTemplates = async () =>
  await call({ url: "/activity/get/assessment/templates/by/professional" });

export const getAllRehabTemplates = async () =>
  await call({ url: "/activity/get/rehab/templates/by/professional" });

export const getProtocolTemplateById = async (data) =>
  await call({
    url: "/activity/get/protocol/template/by/id",
    data: { data },
  });

export const getRehabTemplateDetail = async (data) =>
  await call({
    url: "/activity/get/rehab/template/by/id",
    data: { data },
  });

export const createHandbookTemplate = async (data) =>
  await call({
    url: "/activity/create/handbook/templates",
    data: { data },
  });

export const duplicateHandbookTemplate = async (data) =>
  await call({
    url: "/activity/duplicate/handbook/template",
    data: { data },
  });

export const createRiskFactorTemplate = async (data) =>
  await call({
    url: "/activity/create/risk/factor/templates",
    data: { data },
  });

export const duplicateRiskFactorTemplate = async (data) =>
  await call({
    url: "/activity/duplicate/risk/factor/template",
    data: { data },
  });

export const getAllRiskFactorTemplates = async () =>
  await call({ url: "/activity/get/risk/factor/templates/by/professional" });

export const getRiskFactorTemplateById = async (data) =>
  await call({
    url: "/activity/get/risk/factor/template/by/id",
    data: { data },
  });

export const getRegionAreaStructureLists = async (data) =>
  await call({
    url: "/activity/get/risk/factor/human/components",
    data: { data },
  });

export const getAllActivityPlanTemplates = async () =>
  await call({ url: "/activity/get/activity/plan/templates/by/professional" });

export const getActivityPlanTemplateById = async (data) =>
  await call({
    url: "/activity/get/activity/plan/template/by/id",
    data: { data },
  });

export const getAllFeedbackParameters = async () =>
  await call({ url: "/activity/get/all/feedback/parameters" });

export const getFeedbackForChart = async (data) =>
  await call({ url: "/activity/get/feedback/chart", data: { data } });

export const setActivityFeedbackParameters = async (data) => {
  try {
    const ret = await call({
      url: "/activity/set/activity/feedback/parameters",
      data: { data },
    });
    let rules = { message: "No feedback checked" };
    if (ret?.message) {
      rules = await call({
        url: "/check/feedbacksRules",
        data: { data },
      });
    }
    return { setActivity: ret, check: rules };
  } catch (e) {
    return { error: e };
  }
};

export const getActivityFeedbackParameters = async () =>
  await call({ url: "/activity/get/all/feedback/parameters" });

export const getAllActivitiesTypes = async (data) =>
  await call({
    url: "/activity/get/all/activities/types",
    data: { data },
  });

export const removeTemplateById = async (data) =>
  await call({ url: "/remove/mylab/template", data: { data } });

export const getAllAssessmentTests = async (data) =>
  await call({
    url: "/assessments/test/list",
    data: { data },
  });

export const updateDefaultParameter = async (data) =>
  await call({
    url: "/update/default/parameter",
    data: { data },
  });

export const getAssessmentTestById = async (data) =>
  await call({
    url: "/assessments/test/by/id",
    data: { data },
  });

export const getDefaultParameter = async (data) =>
  await call({
    url: "/get/default/parameter",
    data: { data },
  });
