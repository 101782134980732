import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { Children, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Controller, useForm } from "react-hook-form";
import "../App.css";
import { SpAutocomplete } from "../components/atoms/SpAutocomplete";
import SpButton from "../components/atoms/SpButton";
import SpDialog from "../components/atoms/SpDialog";
import SpCalendarDragAndDropItems from "../components/atoms/SpDragAndDropCalendarItems";
import { SpSelect, SpSelectMenuItem } from "../components/atoms/SpSelect";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import SpText from "../components/atoms/SpText";
import SpTextArea from "../components/atoms/SpTextArea";
import SpTextInput from "../components/atoms/SpTextInput";
import {
  getAllActivitiesTypes,
  getAllActivityPlanTemplates,
  getAllAssessmentTemplates,
  getAllFeedbackParameters,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllTrainingTemplates,
} from "../models/actions/Activity";
import {
  createCalendarActivityMonitoring,
  getAllAgendaActivityByProfessional,
  updateCalendarActivityMonitoring,
} from "../models/actions/CalendarActivity";
import { getExerciseTemplates } from "../models/actions/Excercise";
import { getPatientsByProfessionalDetails } from "../models/actions/Professionals";
import {
  formatDateTimeToLocal,
  getCalendarEventsDates,
  getDateFormat,
  momentDefinition,
  rollbar,
} from "../utils/common";
import AgendaCalendarEventCard from "./agenda/AgendaCalendarEventCard";
import A1_AssessmentInputDetails from "./agenda/inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "./agenda/inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "./agenda/inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "./agenda/inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "./agenda/inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "./agenda/inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "./agenda/inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "./agenda/inputDetails/A8_RehabilitationInputDetails";
import ColoredEventCell from "./agenda/ColoredEventCell";
import { getLanguage, labels, psTranslate } from "./shared/translations";
import ColoredEventCellDay from "./agenda/ColoredEventCellDay";
import ColoredEventCellWeek from "./agenda/ColoredEventCellWeek";

const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
];

moment.locale(getLanguage(), momentDefinition);
const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const Agenda = ({ setLoading, ...props }) => {
  const [professionalPatients, setProfessionalPatients] = useState([]);
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [assessmentTemplateSelected, setAssessmentTemplateSelected] =
    useState(false);
  const [openDetailActivity, setOpenDetailActivity] = useState(false);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const [eventDetail, setEventDetail] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [activityFormError, setActivityFormError] = useState(true);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [templatesList, setTemplatesList] = useState([]);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [error, setError] = useState("");
  const [monthSelected, setMonthSelected] = useState(moment());
  const [viewSelected, setViewSelected] = useState("month");
  const [subactivities, setSubactivities] = useState([]);
  const [activityPlanTemplatesList, setActivityPlanTemplatesList] = useState(
    []
  );
  //React hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!draggedEvent) {
      reset({ id_activity_type: null });
    } else {
      setDraggedEvent(null);
    }
  }, [openAddActivity]);

  const fetchDataInitial = async () => {
    try {
      const [
        activityTypeResponse,
        feedbackParametersList,
        professionalPatients,
        templatesFound,
      ] = await Promise.all([
        getAllActivitiesTypes(),
        getAllFeedbackParameters(),
        getPatientsByProfessionalDetails(),
        getAllActivityPlanTemplates(),
      ]);

      setActivityTypeList(activityTypeResponse);
      feedbackParametersList && setLoadParametersData(feedbackParametersList);
      setProfessionalPatients(professionalPatients);
      templatesFound && setActivityPlanTemplatesList(templatesFound);
    } catch (error) {
      rollbar.error("Agenda - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [monthSelected]);

  const fetchData = async () => {
    try {
      const { startOfMonth, endOfMonth } = getCalendarEventsDates({
        monthSelected,
      });
      const responseEvents = await getAllAgendaActivityByProfessional({
        start_date: startOfMonth,
        end_date: endOfMonth,
        hide_feedbacks: true,
      });
      Rollbar.configure({
        checkIgnore: () => false,
      });
      Rollbar.configure({
        checkIgnore: () => true,
      });

      return updateEventsList(responseEvents);
    } catch (error) {
      rollbar.error("Agenda - fetchData", error);
      props.snackbarShowErrorMessage(error);
    }
    return eventsList;
  };

  const getSubactivities = () => {
    let acitivitySelected;
    if (activityTypeList)
      acitivitySelected = activityTypeList.find(
        ({ id }) => id == getValues("id_activity_type")
      );

    if (
      acitivitySelected &&
      !["rehabilitation", "treatment", "supplement"].includes(
        acitivitySelected.key
      )
    )
      return (
        <Grid item xs={6}>
          <Controller
            render={(props) => (
              <SpSelect
                label={labels.patient.patientPlan.selectSubactivity}
                value={props.value}
                formControlWidth={"100%"}
                selectPlaceholder={labels.patient.patientPlan.selectSubactivity}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
                isPrint={false}
              >
                {activityTypeList
                  .find(({ id }) => id == getValues("id_activity_type"))
                  ?.subactivities.map(({ id, name }) => (
                    <SpSelectMenuItem key={id} value={id}>
                      {name}
                    </SpSelectMenuItem>
                  ))}
              </SpSelect>
            )}
            defaultValue={""}
            name={`id_subactivity`}
            control={control}
          />
        </Grid>
      );

    return <></>;
  };

  const MyCustomHeader = ({ label }) => (
    <div style={{ width: "100%", backgroundColor: "#333333", color: "#FFF" }}>
      <div style={{ color: "#FFF" }}>{label}</div>
    </div>
  );

  const handleNewEventSelected = (event) => {
    setLoadParametersList([]);
    setOpenAddActivity(true);
    setOpenDetailActivity(false);
    setValue("start_date", moment(event.start).format("YYYY-MM-DD"));
  };

  const handleEventDetailSelected = (event) => {
    const tempSubactivities = activityTypeList?.find(
      ({ id }) => id == event.id_activity_type
    )?.subactivities;
    setSubactivities(tempSubactivities);
    setValue("id_activity_type", event.id_activity_type);
    setLoadParametersList(
      event.feedbacks?.map((feedback) => feedback.feedback_parameter) ?? []
    );
    setEventDetail(event);
    setOpenDetailActivity(true);
  };

  const updateEventsList = (eventsListData) => {
    const newEventsList = [];
    eventsListData.map((event, eventIdx) => {
      const dateStartLocal = formatDateTimeToLocal({
        date: event?.start_date,
        time: event?.start_time,
      });
      const dateEndLocal = formatDateTimeToLocal({
        date: event?.start_date,
        time: event?.end_time,
      });

      newEventsList.push({
        id: event?.id,
        title: event?.activity_datum?.activity_type?.name,
        description: "",
        start: getDateFormat(
          moment(dateStartLocal).format("YYYY-MM-DD"),
          moment(dateStartLocal).format("HH:mm:ss")
        ),
        end: getDateFormat(
          moment(dateStartLocal).format("YYYY-MM-DD"),
          moment(dateEndLocal).format("HH:mm:ss")
        ),
        start_time: event?.start_time,
        end_time: event?.end_time,
        type: event?.activity_datum?.name,
        event_note: event?.event_note,
        id_presentation: event?.id_presentation,
        id_subactivity: event?.activity_datum?.id_subactivity,
        presentation: event?.presentation,
        id_activity_type: event?.activity_datum?.activity_type?.id,
        media: event?.activity_datum?.activity_type?.key
          ? require(`../assets/activities/${event?.activity_datum?.activity_type?.key}.png`)
          : null,
        color: event?.color,
        data: event?.activity_datum?.data,
        feedbacks: event?.activity_feedbacks[0]
          ?.activity_resp_parameter_feedbacks
          ? event.activity_feedbacks[0].activity_resp_parameter_feedbacks
          : [],
        archiving_status: event?.archiving_status,
        patient: event?.monitoring?.patient,
        is_from_group_plan: event?.is_from_group_plan,
        id_activity_groups: event?.id_activity_groups,
      });
    });
    setEventsList(newEventsList);
    return newEventsList;
  };

  //rendering custom card
  const AgendaEventWrapper = (props) => {
    return React.cloneElement(Children.only(props.children), {
      style: {
        ...props.children.props.style,
        width: "96%",
        backgroundColor: props.event.color,
        padding: `8px`,
        color: "white !important",
      },
    });
  };

  const dateHeader = ({ date, label }) => {
    if (moment(monthSelected).format("MM") === moment(date).format("MM")) {
      return <h3 style={{ color: "lightGray" }}>{label}</h3>;
    } else {
      return <h3 style={{ color: "gray" }}>{label}</h3>;
    }
  };

  const goToBackGeneral = (toolbar) => {
    try {
      let newDate = toolbar.date;

      switch (toolbar.view) {
        case "month":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth() - 1,
            newDate.getDate()
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "week":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() - 7
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "day":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() - 1
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;

        default:
          break;
      }
    } catch (error) {
      rollbar.error("PatientsPlan - goToBackGeneral", error);
    }
  };
  const goToNextGeneral = (toolbar) => {
    try {
      let newDate = toolbar.date;

      switch (toolbar.view) {
        case "month":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth() + 1,
            newDate.getDate()
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "week":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() + 7
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "day":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() + 1
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;

        default:
          break;
      }
    } catch (error) {
      rollbar.error("PatientsPlan - goToNextGeneral", error);
    }
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      goToBackGeneral(toolbar);
    };

    const goToNext = () => {
      goToNextGeneral(toolbar);
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.onNavigate("current", now);
      setMonthSelected(now);
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <SpText variant="monthYearCalendar">
          {toolbar.view === "day" && (
            <>
              <b>{date.format("DD")}</b>{" "}
            </>
          )}
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </SpText>
      );
    };

    return (
      <div
        container
        className="rbc-toolbar"
        spacing={1}
        style={{ marginBottom: "1%", justifyContent: "space-evenly" }}
      >
        <label style={{ justifyContent: "center" }} className={"label-date"}>
          {label()}
        </label>

        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF" }}
            className="btn-back"
            onClick={() => {
              setViewSelected("month");
            }}
          >
            {labels.groups.plan.month}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-current"
            onClick={() => {
              setViewSelected("week");
            }}
          >
            {labels.groups.plan.week}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-next"
            onClick={() => {
              setViewSelected("day");
            }}
          >
            {labels.groups.plan.day}
          </button>
        </div>

        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-back"
            onClick={goToBack}
          >
            {labels.groups.plan.prev}
          </button>
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-current"
            onClick={goToCurrent}
          >
            {labels.groups.plan.today}
          </button>
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-next"
            onClick={goToNext}
          >
            {labels.groups.plan.next}
          </button>
        </div>
      </div>
    );
  };

  const handleDragStart = (event) => {
    setDraggedEvent(event);
    setDisplayDragItemInCell(false);
  };

  const dragFromOutsideItem = () => {
    return draggedEvent;
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start: new Date(start),
      end: new Date(start),
      allDay: allDay,
    };

    handleEventDropFromOutside({ draggedEvent, event, start, end });
    setDraggedEvent(null);
  };

  const handleEventDropFromOutside = ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    const tempSubactivities = activityTypeList?.find(
      ({ id }) => id == draggedEvent?.id
    )?.subactivities;
    setSubactivities(tempSubactivities);

    setValue("id_activity_type", draggedEvent.id);
    setValue("start_date", moment(start).format("YYYY-MM-DD"));
    setLoadParametersList([]);
    setOpenAddActivity(true);
    setDisplayDragItemInCell(true);
  };

  const handleEventDrop = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    let allDay = event?.allDay;

    if (!event?.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event?.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
    const nextEvents = eventsList.map((existingEvent) => {
      return existingEvent.id === event?.id
        ? { ...existingEvent, start, end, allDay }
        : existingEvent;
    });
    event.start_date = moment(start);
    event.end_date = moment(end);
    await updateActivity(event);
    setEventsList(nextEvents);
  };

  const updateActivity = async (data) => {
    if (data.start_time && data.end_time) {
      const date = moment();
      const start_time = moment(data.start_time, "HH:mm:ss");
      const end_time = moment(data.end_time, "HH:mm:ss");
      date.set({
        hour: start_time.format("HH"),
        minute: start_time.format("mm"),
      });
      data.start_time = moment(date).utc().format("HH:mm");
      date.set({
        hour: end_time.format("HH"),
        minute: end_time.format("mm"),
      });
      data.end_time = moment(date).utc().format("HH:mm");
    }
    try {
      const updatedActivity = await updateCalendarActivityMonitoring({
        ...data,
        id_patient: eventDetail?.patient?.id,
      });
      if (!updatedActivity.error) {
        props.snackbarShowMessage(updatedActivity.message);
        resetFields();
      } else {
        props.snackbarShowErrorMessage(updatedActivity.error);
      }
    } catch (error) {
      rollbar.error("PatientsPlan - updateActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const createActivityObject = (data, id_activity_type) => {
    switch (id_activity_type) {
      case 1:
        data.details_data = {
          assessment_motivation: data.assessment_motivation,
          id_template: data?.id_template,
        };
        break;
      case 2:
        data.details_data = {
          name: data.name,
          treatments: data.treatments,
          treatments_motivation: data.treatments_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 3:
        data.details_data = {
          name: data.name,
          excercises: data.excercises,
          excercises_motivation: data.excercises_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 4:
        data.details_data = {
          name: data.name,
          training: data.training,
          training_motivation: data.training_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 5:
        data.details_data = { rest_motivation: data.rest_motivation };
        data.feedback_parameter = loadParametersList;
        break;
      case 6:
        data.details_data = {
          appointment_motivation: data.appointment_motivation,
        };
        break;
      case 7:
        data.details_data = {
          race_motivation: data.race_motivation,
          race_minutes: data.race_minutes,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 8:
        data.details_data = {
          name: data.name,
          rehabilitations: data.rehabilitations,
          rehabilitation_motivation: data.rehabilitation_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 9:
        data.details_data = {
          name: data.name,
          supplements: data.supplements,
          supplement_motivation: data.supplement_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      default:
        break;
    }
    return data;
  };

  const createActivity = async (data) => {
    try {
      if (!getValues("id_patient")) {
        setError(labels.groups.errors.selectAtLeastAPatient);
        return null;
      }
      const id_subactivity = getValues("id_subactivity");
      setError(null);
      const id_activity_type = getValues("id_activity_type");
      const date = moment(getValues("start_date"), "YYYY-MM-DD");
      const start_time = moment(data.start_time, "HH:mm:ss");
      const end_time = moment(data.end_time, "HH:mm:ss");
      date.set({
        hour: start_time.format("HH"),
        minute: start_time.format("mm"),
      });
      data.start_time = moment(date).utc().format("HH:mm");
      date.set({
        hour: end_time.format("HH"),
        minute: end_time.format("mm"),
      });
      data.id_subactivity = id_subactivity;

      data.end_time = moment(date).utc().format("HH:mm");
      data.start_date = moment(date).format("YYYY-MM-DD");
      data.feedback_parameter =
        data.activity_feedbacks?.[0]?.activity_resp_parameter_feedbacks?.map(
          (el) => el.feedback_parameter
        );
      data = createActivityObject(data, id_activity_type);

      Rollbar.configure({
        checkIgnore: () => false,
      });
      rollbar.info(
        " Agenda: - createActivity",
        JSON.stringify({
          ...data,
          id_patient: data?.id_patient,
          is_from_agenda: 1,
        })
      );
      Rollbar.configure({
        checkIgnore: () => true,
      });
      const createdActivity = await createCalendarActivityMonitoring({
        ...data,
        id_patient: data?.id_patient,
        is_from_agenda: 1,
      });
      if (!createdActivity.error) {
        props.snackbarShowMessage(createdActivity?.message);
        const newEvents = await resetFields();
        return [createdActivity.id, newEvents];
      } else {
        props.snackbarShowErrorMessage(createdActivity?.error);
      }
    } catch (error) {
      rollbar.error("Agenda - createActivity", error);
      props.snackbarShowErrorMessage(error);
    }

    return null;
  };

  const resetFields = () => {
    reset();
    setOpenAddActivity(false);
    setAssessmentTemplateSelected(false);
    setLoadParametersList([]);
    return fetchData();
  };

  //method to render input details based on the type of activity selected
  const renderInputDetails = () => {
    const Detail = INPUT_DETAILS[getValues("id_activity_type")];
    return (
      Detail && (
        <Detail
          subactivities={subactivities}
          idActivityType={getValues("id_activity_type")}
          watchTemplateSelect={watchTemplateSelect}
          selectedTemplate={getValues("id_template")}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          control={control}
          setValue={setValue}
          getValues={getValues}
          register={register}
          isPrint={false}
        />
      )
    );
  };

  const watchStartTime = watch("start_time");
  const watchActivityType = watch("id_activity_type");
  const watchTemplateSelect = watch("id_template");
  const watchEndTime = watch("end_time");

  const fetchTemplateByActType = async () => {
    try {
      setValue("id_template", "");
      setAssessmentTemplateSelected(false);
      let templateData = [];
      switch (getValues("id_activity_type")) {
        case 1:
          templateData = await getAllAssessmentTemplates();
          break;
        case 2:
          templateData = await getAllProtocolTemplates();
          break;
        case 3:
          templateData = await getExerciseTemplates();
          break;
        case 4:
          templateData = await getAllTrainingTemplates();
          break;
        case 8:
          templateData = await getAllRehabTemplates();
          break;
        case 9:
          templateData = [];
          break;
        default:
          break;
      }
      if (templateData) {
        setTemplatesList(templateData);
      }
    } catch (error) {
      rollbar.error("Agenda - fetchTemplateByActType", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [monthSelected]);

  useEffect(() => {
    fetchDataInitial();
  }, []);

  useEffect(() => {
    if (getValues("start_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchStartTime]);

  useEffect(() => {
    if (getValues("end_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchEndTime]);

  useEffect(() => {
    fetchTemplateByActType();
  }, [watchActivityType]);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <SpText variant={"h1"}>{labels.calendar.title}</SpText>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <SpCalendarDragAndDropItems
            activitiesAvailable={activityTypeList}
            handleDragStart={handleDragStart}
          />
          <Grid item xs={11} style={{ minHeight: "750px" }}>
            <div>
              {
                <DragAndDropCalendar
                  selectable
                  step={30}
                  min={new Date(0, 0, 0, 5, 0, 0)}
                  max={new Date(0, 0, 0, 23, 0, 0)}
                  events={eventsList}
                  scrollToTime={new Date()}
                  defaultDate={new Date()}
                  view={viewSelected}
                  onEventDrop={handleEventDrop}
                  popup={true}
                  dragFromOutsideItem={
                    displayDragItemInCell ? dragFromOutsideItem : null
                  }
                  onDropFromOutside={onDropFromOutside}
                  handleDragStart={handleDragStart}
                  onSelectEvent={handleEventDetailSelected}
                  onSelectSlot={handleNewEventSelected}
                  draggableAccessor={(event) =>
                    !(
                      event.archiving_status ===
                        "completed_and_feedback_not_sent" ||
                      event.archiving_status === "completed_and_feedback_sent"
                    )
                  }
                  tooltipAccessor={(event) => {
                    const tooltip = Object.entries(event?.data ?? {}).find(
                      ([key]) => key.includes("_motivation")
                    )?.[1];
                    return tooltip ? tooltip : event.title;
                  }}
                  eventPropGetter={(event, start, end, isSelected) => {
                    const backgroundColor = event.color;
                    const style = {
                      backgroundColor: backgroundColor,
                      borderRadius: "5px",
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      border: "1px solid white",
                      display: "block",
                    };
                    return {
                      style: style,
                    };
                  }}
                  messages={{
                    showMore: (total) => (
                      <a
                        style={{ cursor: "pointer" }}
                        onMouseOver={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {`+${total} ${labels.groups.plan.viewMore}`}
                      </a>
                    ),
                  }}
                  components={{
                    timeGutterHeader: (props) => (
                      <div style={{ color: "red" }}>{props.children}</div>
                    ),
                    month: {
                      toolbar: (toolbar) => CustomToolbar(toolbar),

                      header: MyCustomHeader,
                      dateHeader: dateHeader,

                      event: ColoredEventCell,
                      eventWrapper: AgendaEventWrapper,
                    },
                    week: {
                      toolbar: (toolbar) => CustomToolbar(toolbar),

                      header: MyCustomHeader,
                      dateHeader: dateHeader,

                      event: ColoredEventCellWeek,
                      eventWrapper: AgendaEventWrapper,
                    },
                    day: {
                      toolbar: (toolbar) => CustomToolbar(toolbar),

                      header: MyCustomHeader,
                      dateHeader: dateHeader,

                      event: ColoredEventCellDay,
                      eventWrapper: AgendaEventWrapper,
                    },
                  }}
                  localizer={localizer}
                />
              }
            </div>
          </Grid>
        </Grid>
      </Grid>
      {eventDetail && (
        <SpDialog
          variant="bigDialog"
          open={openDetailActivity}
          setOpen={setOpenDetailActivity}
        >
          <AgendaCalendarEventCard
            event={eventDetail}
            fetchData={fetchData}
            setOpenDetailActivity={setOpenDetailActivity}
            updateActivity={updateActivity}
            openDetailActivity={openDetailActivity}
            createActivityObject={createActivityObject}
            loadParametersData={loadParametersData}
            loadParametersList={loadParametersList}
            setLoadParametersList={setLoadParametersList}
            subactivities={subactivities}
            setValue={setValue}
            getValues={getValues}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            activitySelectedKey={
              activityTypeList.find(
                ({ id }) => id == getValues("id_activity_type")
              )?.key
            }
          />
        </SpDialog>
      )}
      <SpDialog
        variant="bigDialog"
        open={openAddActivity}
        setOpen={setOpenAddActivity}
        onCloseFunctions={resetFields}
        title={labels.calendar.addDialog.title}
      >
        <form onSubmit={handleSubmit(createActivity)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Grid item style={{ maxWidth: "100%", marginBottom: "2%" }}>
              <SpAutocomplete
                style={{ width: "100%" }}
                formControlWidth={"100%"}
                selectPlaceholder={labels.agenda.selectPatient}
                value={professionalPatients[getValues("id_patient")]}
                onChange={(e, newValue) => {
                  setValue("id_patient", newValue?.id);
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <SpText
                      style={{ marginLeft: "8px" }}
                      variant="h4ComponentLabel"
                    >
                      {professionalPatients[option.id]?.givenName +
                        " " +
                        professionalPatients[option.id]?.familyName}
                    </SpText>
                  ))
                }
                options={professionalPatients}
                getOptionLabel={(option) =>
                  (option?.givenName + " " + option?.familyName).toString()
                }
                getOptionSelected={(option, value) => option?.id === value}
              />
            </Grid>
            {error && <SpText variant="h4ComponentLabelError">{error}</SpText>}
            <Grid item container direction="row" xs={12}>
              <Grid item xs={2}>
                <SpTextInput
                  type="time"
                  name="start_time"
                  min="05:00"
                  max="22:00"
                  defaultValue="08:00"
                  inputRef={register({ required: true })}
                  label={
                    labels.patient.viewPlan.addActivity.inputForm.start_time
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <SpTextInput
                  type="time"
                  name="end_time"
                  min="05:00"
                  max="22:00"
                  defaultValue="09:00"
                  inputRef={register({ required: true })}
                  label={labels.patient.viewPlan.addActivity.inputForm.end_time}
                />
              </Grid>

              <Grid item xs={4}>
                <Grid item xs={12}>
                  <Controller
                    render={(props) => (
                      <SpSelect
                        label={labels.patient.patientPlan.chooseActivityPlan}
                        style={{ marginTop: "1%" }}
                        formControlWidth={"100%"}
                        value={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                          setTemplateHelper(!templateHelper);
                        }}
                      >
                        {activityPlanTemplatesList.map((p) => (
                          <SpSelectMenuItem key={p?.id} value={p?.id}>
                            {p?.template_name}
                          </SpSelectMenuItem>
                        ))}
                      </SpSelect>
                    )}
                    defaultValue={""}
                    name={`id_activity_template`}
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Controller
                  render={(props) => (
                    <SpSelect
                      label={
                        labels.patient.viewPlan.addActivity.inputForm
                          .chooseActivity
                      }
                      style={{ marginTop: "1%" }}
                      value={props.value ?? []}
                      onChange={(e) => {
                        props.onChange(e.target.value);
                        setValue("quantity_training", 0);
                        setValue("quantity_treatment", 0);
                        setValue("quantity_rehabilitation", 0);
                        setValue("quantity_excercise", 0);
                      }}
                    >
                      {activityTypeList.map((p) => (
                        <SpSelectMenuItem key={p?.id} value={p?.id}>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require(`../assets/activities/${p?.key}${
                                props?.value === p?.id ? "" : "_black"
                              }.png`)}
                              style={{ maxWidth: 25, maxHeight: 25 }}
                              alt={"img"}
                            />
                            <SpText
                              color={props?.value === p?.id ? "white" : "black"}
                              style={{
                                color: "black !important",
                                marginLeft: "5px",
                              }}
                            >
                              {psTranslate(p?.name)}
                            </SpText>
                          </div>
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  defaultValue={""}
                  name={`id_activity_type`}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container direction="row">
              <Grid item xs={4}>
                {templatesList.length > 0 &&
                  !getValues("id_activity_template") && (
                    <Controller
                      render={(props) => (
                        <SpSelect
                          label={
                            labels.patient.viewPlan.addActivity.inputForm
                              .chooseTemplateActivity
                          }
                          style={{ marginTop: "1%" }}
                          formControlWidth={"100%"}
                          value={props?.value}
                          onChange={(e) => {
                            props.onChange(e.target.value);
                          }}
                        >
                          {templatesList.map((p) => (
                            <SpSelectMenuItem key={p?.id} value={p?.id}>
                              <SpText
                                color={
                                  props?.value === p?.id ? "white" : "black"
                                }
                                style={{
                                  color: "black !important",
                                  marginLeft: "5px",
                                }}
                              >
                                {p?.name}
                              </SpText>
                            </SpSelectMenuItem>
                          ))}
                        </SpSelect>
                      )}
                      defaultValue={""}
                      name={`id_template`}
                      control={control}
                    />
                  )}
              </Grid>

              <Grid item xs={4}>
                {getSubactivities()}
              </Grid>
            </Grid>

            <div style={{ marginTop: "1%", marginBottom: "1%" }}>
              {renderInputDetails()}
            </div>
            <SpTextArea name="event_note" label="Note" inputRef={register} />
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                <SpButton
                  buttonType={"accept"}
                  variant="none"
                  text={labels.patient.viewPlan.addActivity.title}
                  style={{ marginTop: "4%", width: "100%" }}
                  type="submit"
                />
              </Grid>
              <Grid item xs={6}>
                <SpButton
                  buttonType={"accept"}
                  variant="none"
                  text={labels.patient.viewPlan.addActivity.addAndCompile}
                  style={{ marginTop: "4%", width: "100%" }}
                  type="submit"
                  onClick={async (e) => {
                    e.preventDefault();
                    setLoading(true);
                    const result = await createActivity(getValues());
                    if (result) {
                      const [idEvent, newEventList] = result;
                      const newEvent = newEventList.find(
                        (evnt) => evnt.id === idEvent
                      );
                      if (newEvent) handleEventDetailSelected(newEvent);
                    }
                    setLoading(false);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </SpDialog>
    </>
  );
};

export default withSnackbar(Agenda);
